<template>
    <div>
        <section class="food-fast-main">
            <!-- searchbar -->
            <div class="select-wishlist-store search-bar-filter">
                <div class="container">
                    <div class="search-bar">
                        <form @submit.prevent="pstGetSearch">
                            <div class="form-row">
                                <div class="form-group mb-0">
                                    <label for="staticEmail2" class="sr-only">search</label>
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="staticEmail2"
                                    placeholder="Search for item / Category / Store"
                                    v-model="searchqry"
                                    />
                                </div>
                                <button
                                    :style="colorObj"
                                    type="button"
                                    class="btn button-style hoverEffect"
                                    @click="pstGetSearch"
                                    :disabled="!searchqry"
                                >
                                <span class="d-md-none">
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="15px" height="15px" viewBox="123.295 0 25.001 25" enable-background="new 123.295 0 25.001 25" xml:space="preserve">
                                    <g>
                                    <g>
                                        <g>
                                        <path fill="currentColor" d="M134.303,0c-6.069,0-11.008,4.938-11.008,11.01c0,6.07,4.938,11.01,11.008,11.01
                                            c6.072,0,11.012-4.938,11.012-11.01C145.314,4.938,140.375,0,134.303,0z M134.303,19.986c-4.948,0-8.977-4.027-8.977-8.978
                                            s4.028-8.978,8.977-8.978c4.951,0,8.979,4.027,8.979,8.978C143.279,15.959,139.254,19.986,134.303,19.986z"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                        <path fill="currentColor" d="M147.997,23.266l-5.827-5.828c-0.396-0.396-1.041-0.396-1.436,0c-0.398,0.398-0.398,1.041,0,1.438
                                            l5.824,5.826C146.76,24.9,147.018,25,147.279,25c0.259,0,0.52-0.1,0.718-0.298C148.395,24.307,148.395,23.662,147.997,23.266z"/>
                                        </g>
                                    </g>
                                    </g>
                                    </svg>
                                </span>
                                Search </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Recent view stores -->
            <section v-if="!searchqry" class="search-page sponsored-stores" :class="showMapViewAfterSearch ? 'nearby' :''">
                <div class="container">
                    <div v-if="loading==true">
                        <p class="text-center">
                            <img src="../../assets/images/loading.gif" />
                        </p>
                    </div>
                    <div class="sectionheadingupper d-flex flex-wrap justify-content-between align-items-center">
                        <h2 class="section-heading" v-if="storelist.status=='1' && loading==false">Recently viewed stores</h2>
                    </div>
                    <div class="row advance-search-row" v-if="storelist.status=='1'">
                        <div class="col-sm-6 col-md-4" v-show="showGridViewAfterSearch" v-for="(rstore,index) in recent_stores" :key="index">
                            <div class="shop-main" @click="shopRedirection(rstore,storeselectionfrom.recentstore)">
                                <router-link to="" class="shop-image">
                                    <img v-if="rstore.store_logo!=''" :src="rstore.store_logo" alt="product">
                                    <avatar v-else :fullname="rstore.store_name.split(' ')[0]" :radius="0" :color="avatarBackgroundColor"></avatar>
                                    <span v-if="rstore.is_open == 1" class="oder-status bg-green">Order Now</span>
                                    <span v-else class="oder-status bg-red">Closed Now</span>
                                </router-link>
                                <div class="shop-details">
                                    <ul class="list-unstyled shop-catagory">
                                        <li class="category-sec">
                                            <a href="javascript:void(0)"><img :src="rstore.category_black_icon" alt="store category"></a>
                                        </li>
                                        <li class="whislist" v-if="rstore.is_favourite == 1">
                                            <img src="../../assets/images/svgs/whishlist-icon.svg" alt="favourite store">
                                        </li>
                                        <li class="khata" v-if="rstore.is_khata == 1">
                                            <img src="../../assets/images/svgs/khata-icon.svg" alt="khata store">
                                        </li>
                                        <li class="wholesaler-icon" v-if="rstore.is_whole_seller == 1">
                                            <img src="../../assets/images/svgs/ic_wholesaller.svg" alt="wholesaler tile">
                                        </li>
                                    </ul>
                                    <h5 class="recent-view-store">{{rstore.store_name | strippedStorename}}</h5>
                                    <p class="recent-view-store-distance distance">{{ rstore.distance }}</p> 
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div v-if="storelist.status=='0' && loading==false">
                        <div class="empty-box text-center">
                            <div class="contents">
                                <div class="empty-image search-not-found">
                                    <img src="../../assets/images/svgs/search-not-found.svg" alt="search-not-found">
                                </div>
                                <h3>{{storelist.message}}</h3>
                            </div>
                            <a href="/" :style="colorObj" class="btn button-style btn-large hoverEffect">Go to Home</a>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Sponsored Stores start -->
            <section class="search-page sponsored-stores" :class="showMapViewAfterSearch ? 'nearby' :''">
                <div class="container">
                    <div v-if="loading==true">
                        <p class="text-center">
                            <img src="../../assets/images/loading.gif" />
                        </p>
                    </div>
                    <div class="sectionheadingupper d-flex flex-wrap justify-content-between align-items-center">
                      <h2 class="section-heading" v-if="storelist.status=='1' && loading==false">{{search_result_name}}</h2>
                    </div>
                    <div class="row advance-search-row" v-if="storelist.status=='1'">
                        <div class="col-sm-6 col-md-4" v-show="showGridViewAfterSearch" v-for="(store,index) in storelist.data" v-bind:key="index">
                            <div class="shop-main" @click="shopRedirection(store,storeselectionfrom.store)">
                            <router-link to="" class="shop-image">
                                <img v-if="store.store_logo!=''" v-bind:src="store.store_logo" alt="product">
                                <avatar v-else :fullname="store.store_name.split(' ')[0]" :radius="0" :color="avatarBackgroundColor"></avatar>
                                <span v-if="store.is_open == 1" class="oder-status bg-green">Order Now</span>
                                <span v-else class="oder-status bg-red">Closed Now</span>
                                <span class="rating-status" v-if="(review_rating_module == 1) ? (store.avg_rating != null && show_review_rating == 1) : false">
                                <img src="../../assets/images/svgs/star-black.svg" alt="rating">
                                <span>{{store.avg_rating}}</span>
                                </span>
                            </router-link>
                            <div class="shop-details">
                                <ul class="list-unstyled shop-catagory">
                                <li class="category-sec">
                                    <a href="javascript:void(0)" @click="getStoreByCategory(store)"><img :src="store.category_black_icon" alt="store category"></a>
                                </li>
                                <li class="whislist" v-if="store.is_favourite == 1">
                                    <img src="../../assets/images/svgs/whishlist-icon.svg" alt="favourite store">
                                </li>
                                <li class="khata" v-if="store.is_khata == 1">
                                    <img src="../../assets/images/svgs/khata-icon.svg" alt="khata store">
                                </li>
                                <li class="wholesaler-icon" v-if="store.is_whole_seller == 1">
                                    <img src="../../assets/images/svgs/ic_wholesaller.svg" alt="wholesaler tile">
                                </li>                      
                                </ul>
                                <h5>{{store.store_name | strippedStorename}}</h5>
                                
                                <p class="distance">{{ store.distance }}</p> 
                            </div>
                            </div>
                        </div>
                    </div>
                    
                    <div v-if="storelist.status=='0' && loading==false">
                        <div class="empty-box text-center">
                            <div class="contents">
                                <div class="empty-image search-not-found">
                                    <img src="../../assets/images/svgs/search-not-found.svg" alt="search-not-found">
                                </div>
                                <h3>{{storelist.message}}</h3>
                            </div>
                            <a href="/" :style="colorObj" class="btn button-style btn-large hoverEffect">Go to Home</a>
                        </div>
                    </div>
                </div>
            </section>
        </section>
        <!-- item type store modal -->
        <div
            class="modal fade"
            id="WishlistStoreTypeModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="WishlistStoreTypeModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body">
                        <div
                            class="col-custom"
                            v-for="(storetype,index) in wishliststoredetailbycategoryid"
                            v-bind:key="index"
                            >    
                            <div class="shop-main">
                              <a href="javascript:void(0)" @click="shopRedirection(storetype, storeselectionfrom.storetype)">
                                <img v-if="storetype.store_logo!=''" v-bind:src="storetype.store_logo" alt="product">
                                <avatar v-else :fullname="storetype.store_name.split(' ')[0]" :size="200" :radius="0" :color="avatarBackgroundColor"></avatar>
                                <span v-if="storetype.is_open == 1" class="oder-status bg-green">Order Now</span>
                                <span v-else class="oder-status bg-red">Closed Now</span>
                                <span class="rating-status" v-if="(review_rating_module == 1) ? (storetype.avg_rating != null && show_review_rating == 1) : false">
                                    <img src="../../assets/images/svgs/star-black.svg" alt="rating">
                                    <span>{{storetype.avg_rating}}</span>
                                </span>
                              </a>
                                
                                <div class="shop-details">
                                <ul class="list-unstyled shop-catagory">
                                    <li class="category-sec">
                                        <img :src="storetype.category_black_icon" alt="store category">
                                    </li>
                                    <li class="whislist" v-if="storetype.is_favourite == 1">
                                        <img src="../../assets/images/svgs/whishlist-icon.svg" alt="favourite store">
                                    </li>
                                    <li class="khata" v-if="storetype.is_khata == 1">
                                        <img src="../../assets/images/svgs/khata-icon.svg" alt="khata store">
                                    </li>
                                </ul>
                                <h5>{{storetype.store_name | strippedStorename}}</h5>
                                <p class="distance">{{ storetype.distance }}</p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import { bus } from "../../main";
import Avatar from "vue-avatar-component";
import helper from "../../store/helper";
export default {
  name: "SelectWishlistStore",
  components: {
    Avatar,
  },
  mixins:[helper],
  data() {
    return {
      map_center_latitude:null,
      map_center_longitude:null,
      userprofile: null,
      searchqry: "",
      custsearchbody: {
        user_id: "",
        search_query: "",
        lat: "",
        long: "",
      },
      storetype: "",
      deliverytype: "",
      onlineorder: "",
      advncefiltrlist: [],
      loading: false,
      storelist: [],
      storetypearr: [0],
      deliveryarr: [0,1],
      onlineordrarr: [0],
      def: [],
      custadvncenwsearch: {
        user_id: "",
        search_query: "",
        lat: "",
        long: "",
        store_type: "",
        delivery_type: "",
        online_order: ""
      },
      category: {
        shop_category_id: "",
        shop_category_name: "",
        type: "",
      },
      markers:[],
      location_addresses : [],
      showGridViewAfterSearch : true,
      showMapViewAfterSearch : false,
      show_review_rating : 1,
      review_rating_module : 1,
      initializeGoogleMap : {
       streetViewControl: true,
       scaleControl: true,
       center: { lat: 34.04924594193164, lng: 34.04924594193164 },
       zoom: 2,
      },
      selected_active_store : 0,
      infowindow: {lat: 34.04924594193164, lng: 34.04924594193164},
      window_open: false,
      selected_location_name : '',
      infoOptions: {
          pixelOffset: {
            width: 0,
            height: -35
          }
      },
      location_hash:'',
      favrtebody : {
        user_id: "",
        store_id: "",
        is_favourite: "",
        lat:"",
        long:""
      },
      wishliststore:{
          storename:"",
          storeid:"",
          storelogo:"",
          areaname:"",
          categoryname:"",
          categoryid:"",
          categoryblackicon:"",
          selection:0
      },
      wishliststorebystoretype:{
          category_id: "",
          delivery_type: "",
          lat: "",
          long: "",
          online_order: "0",
          store_type: "0",
          user_id: ""
      },
      userlocation:{
        lat:"",
        long:"",
      },
      recentstoreview:{
        user_id:"",
        lat:"",
        long:""
      },
      recent_stores:[],
      wishliststoredetailbycategoryid:[],
      storeselectionfrom:{
          search:1,
          store:2,
          recentstore:3
      },
      search_result_name : "Search Results",
      avatarBackgroundColor:"",
      avatarTextColor:"",
      use_google_map:"",
      previousURL:null,
      storecategory:[],
      initprevbutn:
        '<button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button>',
      initnextbutn:
        '<button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button>',
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
        vm.previousURL = from;
        window.previousURL = from.name;
        localStorage.setItem("PREVIOUS_URL", from.name);
    });
  },
  filters: {
    strippedStorename: function(value) {
      if (value.length > 17) {
        return value.slice(0, 14) + "...";
      }
      return value;
    },
    strippedCategoryName: function(value) {
      if (value.length > 15) {
        return value.slice(0, 12) + "...";
      }
      return value;
    },
  },
  mounted() {
    this.location_hash = location.hash;
    if (localStorage.getItem("user") != null) {
        this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
        this.userprofile = null;
    }
    this.userlocation = localStorage.getItem("location_details");
    this.recentViewStores(this.userprofile,this.userlocation);

    bus.$on("searchQry", data => {
      localStorage.setItem("searchqry", data);
      this.searchqry = localStorage.getItem("searchqry");
    });

    bus.$on("citi_wise_dashboard", response => {
      var category_data = JSON.parse(JSON.stringify(response.data));
      this.storecategory = category_data.category;
    })

    this.def = localStorage.getItem("searchqry");

    if (this.def != "") {
      this.searchqry = this.def;
    }
      localStorage.setItem("home-search",this.searchqry)
    if (this.searchqry != "") {
      this.pstGetSearch();
    }
    this.getAdvnceFltrList();
    window.$('<style>.category-tab .nav-pills .nav-link.active { color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.category-tab .nav-pills .nav-link.active { border-bottom-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.category-tab .categary-detail li.active a { color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.btn-normal:hover { color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body');
    window.$('<style>.nearby .store-list::-webkit-scrollbar-thumb { background-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body')
    this.originalColor();
    
    bus.$on("globalsetting",data => {
      if(Object.keys(data).length != 0){
        this.review_rating_module = parseFloat(JSON.parse(data).review_rating_module);        
        this.show_review_rating = parseFloat(JSON.parse(data).show_review_rating);
        this.use_google_map = JSON.parse(data).use_google_map;
      }
    })

    window.$(document).ready(function(){
      window.$('<style>.nearby .shop-details.active-shop { background-color: '+localStorage.getItem("button_hover_color")+' !important }</style>').appendTo('body');
      window.$('<style>.nearby .shop-details.active-shop .media-body h3 {color: '+localStorage.getItem("css_button_customer_hover_font")+' !important;}</style>').appendTo('body');
    })
    
  },
  updated(){
    window.$('.right-skyblue svg polygon').css({ fill: localStorage.getItem("button_default_color")});
    this.avatarBackgroundColor = this.cancelReOrderButton.backgroundColor;
    this.avatarTextColor = localStorage.getItem("css_button_customer_hover_font");
    window.$('.sponsored-stores .avatar table tbody tr td').css({ color: this.avatarTextColor});
  },
  methods: {
    toggleInfoWindow(index){
      this.selected_active_store = index;
      this.map_center_latitude = parseFloat(this.location_addresses[index].latitude);
      this.map_center_longitude = parseFloat(this.location_addresses[index].longitude);
      this.infowindow.lat = parseFloat(this.location_addresses[index].latitude);
      this.infowindow.lng = parseFloat(this.location_addresses[index].longitude);
      this.selected_location_name = this.location_addresses[index].store_name;
      
      window.$('.store-list').scrollTop(0)
      window.$('.store-list').scrollTop(150*(index))

      this.window_open = true;
    },
    ViewStore(storeId){
      this.$router.push({ path: `/shopdetail?storeid=${storeId}` });
    },
    FocusStore(storeId,index){
      if (this.location_addresses != null && this.location_addresses != undefined)
      {
        this.selected_location_name = this.location_addresses[index].store_name;
        this.map_center_latitude = parseFloat(this.location_addresses[index].latitude);
        this.map_center_longitude = parseFloat(this.location_addresses[index].longitude);
        this.selected_active_store = index;
        this.infowindow.lat = parseFloat(this.location_addresses[index].latitude);
        this.infowindow.lng = parseFloat(this.location_addresses[index].longitude);
        this.window_open = true;    
      }
    },
    showGridView(){
      this.showGridViewAfterSearch = true;
      this.showMapViewAfterSearch = false;
    },
    showMapView(){
      this.showGridViewAfterSearch = false;
      this.showMapViewAfterSearch = true;
    },
    shopRedirection(store,selection)
    {
      if(selection == 3){
        this.wishliststore.storeid = store.store_id;
        this.wishliststore.storename = store.store_name;
        this.wishliststore.areaname = store.area_name;
        this.wishliststore.storelogo = store.store_logo;
        this.wishliststore.categoryname = store.category_name;
        this.wishliststore.categoryid = store.category_id;
        this.wishliststore.categoryblackicon = store.category_black_icon;
        this.wishliststore.selection = selection;
      }
      if(selection == 2){
        this.wishliststore.storeid = store.store_id;
        this.wishliststore.storename = store.store_name;
        this.wishliststore.areaname = store.area_name;
        this.wishliststore.storelogo = store.store_logo;
        this.wishliststore.categoryname = store.category_name;
        this.wishliststore.categoryid = store.category_id;
        this.wishliststore.categoryblackicon = store.category_customer_icon;
        this.wishliststore.selection = selection;
        window.$('#WishlistStoreTypeModal').modal('hide');
      }
      if(selection == 1){
        this.wishliststore.storeid = store.store_id;
        this.wishliststore.storename = store.store_name;
        this.wishliststore.areaname = store.area_name;
        this.wishliststore.storelogo = store.store_logo;
        this.wishliststore.categoryname = store.category_name;
        this.wishliststore.categoryid = store.category_id;
        this.wishliststore.categoryblackicon = store.category_customer_icon;
        this.wishliststore.selection = selection;
      }
      bus.$emit('wishlist-store-obj', this.wishliststore);
      window.$('#WishlistStoreModal').modal('hide');
    },
    getStoreByCategory(category){
      this.category.shop_category_id = category.category_id;
      this.category.shop_category_name = category.category_name;
      this.category.type = "category";
      localStorage.setItem("selected-category", JSON.stringify(this.category));
      this.$router.push({ path: `/search-category?categoryid=${this.category.shop_category_id.toString()}` });
    },
    getWishListStoreByCategory(category){
        this.wishliststorebystoretype.user_id = (localStorage.getItem("user") != null)
            ? JSON.parse(localStorage.getItem("user")).user_id
            : "0";
        this.wishliststorebystoretype.category_id = category.category_id;
        this.wishliststorebystoretype.lat = JSON.parse(localStorage.getItem("location_details")).lat;
        this.wishliststorebystoretype.long = JSON.parse(localStorage.getItem("location_details")).lng;
        
        this.wishliststorebystoretype.store_type = this.storetypearr.length != 0 ? this.storetypearr.join(",") : "";
        this.wishliststorebystoretype.online_order = this.onlineordrarr.length != 0 ? this.onlineordrarr.join(",") : "";

        this.$store
            .dispatch("getcategrywisestore", this.wishliststorebystoretype)
            .then(response => {
            this.wishliststoredetailbycategoryid = JSON.parse(JSON.stringify(response)).data.stores;
            if(JSON.parse(JSON.stringify(response)).status == 1){
              window.$("#WishlistStoreTypeModal").modal("show");
            }
            });
    },
    recentViewStores(user,location){
      this.recentstoreview.user_id = user.user_id;
      this.recentstoreview.lat = JSON.parse(location).lat;
      this.recentstoreview.long = JSON.parse(location).lng;
      this.$store
          .dispatch("recenetviewdstores", this.recentstoreview)
          .then(response => {
            if(response.status == 1){
              this.recent_stores = response.data.recent_stores;
            }
            
          });
    },
    getActmenu(namekey, menuid, mainid) {
      if (namekey == "store_type") {
        let a = window.$("#new" + mainid + menuid).attr("class");
        if (a != undefined && a == "active") {
          if (this.storetypearr.length == 0) {
            this.storetypearr = [];
          } else {
            this.storetypearr.splice(menuid, 1);
          }
          window.$("#new" + mainid + menuid).removeAttr("class");
        } else {
          this.storetypearr.push(menuid);
          window.$("#new" + mainid + menuid).attr("class", "active");
        }
      } else if (namekey == "delivery_type") {
        let a = window.$("#new" + mainid + menuid).attr("class");
        if (a != undefined && a == "active") {
          if (this.deliveryarr.length == 0) {
            this.deliveryarr = [];
            window.$("#new" + mainid + menuid).removeAttr("class");
          } else {
            if(this.deliveryarr.length != 1)
            {
            this.deliveryarr.splice(menuid, 1);
            window.$("#new" + mainid + menuid).removeAttr("class");
            }
          }
          
        } else {
          this.deliveryarr.push(menuid);
          window.$("#new" + mainid + menuid).attr("class", "active");
        }
      } else if (namekey == "online_order") {
          let a = window.$("#new" + mainid + menuid).attr("class");
          if (a != undefined || a == "active") {
            if (this.onlineordrarr.length == 0) {
              this.onlineordrarr = [];
            } else {
              this.onlineordrarr.splice(menuid, 1);
            }

            window.$("#new" + mainid + menuid).removeAttr("class");
          } else {
            this.onlineordrarr.push(menuid);

            window.$("#new" + mainid + menuid).attr("class", "active");
          }
      }
    },
    pstGetSearch() {
        document.body.classList.remove('top-menu-add-padding');
        this.custadvncenwsearch.search_query = this.searchqry;
        if(this.searchqry != ""){
                localStorage.setItem("home-search",this.searchqry)
            }
        this.custadvncenwsearch.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
        this.custadvncenwsearch.lat = JSON.parse(localStorage.getItem("location_details")).lat;
        this.custadvncenwsearch.long = JSON.parse(localStorage.getItem("location_details")).lng;

        this.custadvncenwsearch.store_type = this.storetypearr.length != 0 ? this.storetypearr.join(",") : "";
        this.custadvncenwsearch.delivery_type = this.deliveryarr.length != 0 ? this.deliveryarr.join(",") : "";
        if(this.custadvncenwsearch.delivery_type == "0,1")
        {
          this.custadvncenwsearch.delivery_type="";
        }
        this.custadvncenwsearch.online_order = this.onlineordrarr.length != 0 ? this.onlineordrarr.join(",") : "";

        this.loading = true;
        this.storelist = "";
        var _this=this;

        if(this.location_hash == '#favorite-store'){
        this.search_result_name = 'Favorites';
        this.favrtebody.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
        this.favrtebody.is_favourite = 1;
        this.favrtebody.lat = JSON.parse(localStorage.getItem("location_details")).lat;
        this.favrtebody.long = JSON.parse(localStorage.getItem("location_details")).lng;
        _this.markers =[];
        this.$store
          .dispatch("getcategrywisesearch", this.favrtebody)
          .then(response => {
            this.storelist = response;
            if (response.status == 1 && response.data != undefined)
            {
              response.data.forEach(function(item){
                let marker = { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude)};
                _this.markers.push({ position: marker });
                _this.location_addresses.push(item)
              })
              if(_this.location_addresses != null && _this.location_addresses != undefined)
              {
                this.map_center_latitude = _this.location_addresses[0].latitude;
                this.map_center_longitude = _this.location_addresses[0].longitude;
              }
            }
            
            if(this.searchqry != "" && this.searchqry != null && response.data != undefined)
            {
             if(response.data.filter(x=>x.store_name.toLowerCase().trim() == this.searchqry.toLowerCase().trim()).length > 0)
             {
               localStorage.removeItem("home-search")
             }
            }
            this.loading = false;
          });          
        }
        else{
        this.$store
          .dispatch("getadvancenewserchlist", this.custadvncenwsearch)
          .then(response => {
            this.storelist = response;
            if (response.data != undefined)
            {
              response.data.forEach(function(item){
                let marker = { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude)};
                _this.markers.push({ position: marker });
                _this.location_addresses.push(item)
              })
              if(_this.location_addresses != null && _this.location_addresses != undefined)
              {
                this.map_center_latitude = _this.location_addresses[0].latitude;
                this.map_center_longitude = _this.location_addresses[0].longitude;
              }
            }
            
            if(this.searchqry != "" && this.searchqry != null && response.data != undefined)
            {
             if(response.data.filter(x=>x.store_name.toLowerCase().trim() == this.searchqry.toLowerCase().trim()).length > 0)
             {
               localStorage.removeItem("home-search")
             }
            }
            this.loading = false;
          });
        }
    },
    getAdvnceFltrList() {
      this.$store.dispatch("getadvancefiltrlist").then(response => {
        this.advncefiltrlist = JSON.parse(JSON.stringify(response));
        if(this.advncefiltrlist.status == 1){
          if(localStorage.getItem("user") == null){
            var advncefiltrlist =  this.advncefiltrlist.data.filter(function(item) {
              if (item.key != "store_type") return item;
            });
            this.advncefiltrlist.data = advncefiltrlist;
          }
        }
      });
    }
  }
};
</script>