<template>
  <div>
    <!-- shop profile section start -->
     <section class="shop-prifile-section" v-if="getshopdetaildata != '' && getshopdetaildata.status == '1'">
        <div class="shop-banner-inner">
          <div class="shop-banner-info" :class="is_business_model == 3 ? 'b2c-banner-info':''">
            <div class="banner-searchbar">
              <div class="container">
                  <form class="form-inline shop-searchbar" @submit.prevent="postSearchItem">
                    <div class="form-group mb-2 col-12">
                      <label for="searchbar" class="sr-only">search</label>
                      <input
                        type="text"
                        class="form-control"
                        id="searchproduct"
                        name="searchproduct"
                        v-model="searchproduct"
                        placeholder="Search for item"
                      >
                    </div>
                    <a href="javascript:void(0)" v-if="show_clear_search" class="btn btn-clear mb-2" @click="clearSearchItem()">X</a>
                    <a href="javascript:void(0)" class="btn btn-primary mb-2" @click="postSearchItem()"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" class="_1GTCc" viewBox="5 -1 12 25" height="24" width="24" fill="#23c7fa">
                      <path d="M17.6671481,17.1391632 L22.7253317,22.1973467 L20.9226784,24 L15.7041226,18.7814442 C14.1158488,19.8024478 12.225761,20.3946935 10.1973467,20.3946935 C4.56550765,20.3946935 0,15.8291858 0,10.1973467 C0,4.56550765 4.56550765,0 10.1973467,0 C15.8291858,0 20.3946935,4.56550765 20.3946935,10.1973467 C20.3946935,12.8789625 19.3595949,15.3188181 17.6671481,17.1391632 Z M10.1973467,17.8453568 C14.4212261,17.8453568 17.8453568,14.4212261 17.8453568,10.1973467 C17.8453568,5.97346742 14.4212261,2.54933669 10.1973467,2.54933669 C5.97346742,2.54933669 2.54933669,5.97346742 2.54933669,10.1973467 C2.54933669,14.4212261 5.97346742,17.8453568 10.1973467,17.8453568 Z"/>
                    </svg></a>
                  </form>
              </div>
            </div>
          </div>
        </div>
    </section>
    <!-- shop profile section end -->

    <!-- shop section start -->
    <section class="shop-section">
      <div class="container">
        <div class="banner-searchbar d-xl-none">
          <form class="form-inline shop-searchbar" @submit.prevent="postSearchItem">
            <div class="form-group mb-2 col-12">
              <label for="searchbar" class="sr-only">search</label>
              <input
                type="text"
                class="form-control"
                id="searchproduct1"
                name="searchproduct"
                v-model="searchproduct"
                placeholder="Search for item"
              >
            </div>
            <a href="javascript:void(0)" v-if="show_clear_search" class="btn btn-clear mb-2" @click="clearSearchItem()">X</a>
            <a href="javascript:void(0)" class="btn btn-primary mb-2" @click="postSearchItem()"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" class="_1GTCc" viewBox="5 -1 12 25" height="24" width="24" fill="#23c7fa">
              <path d="M17.6671481,17.1391632 L22.7253317,22.1973467 L20.9226784,24 L15.7041226,18.7814442 C14.1158488,19.8024478 12.225761,20.3946935 10.1973467,20.3946935 C4.56550765,20.3946935 0,15.8291858 0,10.1973467 C0,4.56550765 4.56550765,0 10.1973467,0 C15.8291858,0 20.3946935,4.56550765 20.3946935,10.1973467 C20.3946935,12.8789625 19.3595949,15.3188181 17.6671481,17.1391632 Z M10.1973467,17.8453568 C14.4212261,17.8453568 17.8453568,14.4212261 17.8453568,10.1973467 C17.8453568,5.97346742 14.4212261,2.54933669 10.1973467,2.54933669 C5.97346742,2.54933669 2.54933669,5.97346742 2.54933669,10.1973467 C2.54933669,14.4212261 5.97346742,17.8453568 10.1973467,17.8453568 Z"/>
            </svg></a>
          </form>
        </div>

        <div class="row">
          <div class="col-12 col-md-auto catagory-row" id="CatagoryRow">
            <div class="sticky-category fixed-category-overflow">
              <div class="fixed-category-wrap">
                <button type="button" class="close" id="CategoriesClose" @click="hideCatagoryToggle()">
                  <span aria-hidden="true">×</span>
                </button>
                <button type="button" class="close" id="FilterClose" @click="hideFilterToggle()">
                  <span aria-hidden="true">×</span>
                </button>
                
                <div class="categories" id="CategoriesMain"  v-if="getcategorylist.status == '1'">
                  <h2 class="section-heading mb-2" v-b-toggle.Categoriescollapse>{{ getshopdetaildata.data.store.category_label }}</h2>

                  <b-collapse visible id="Categoriescollapse">
                    <b-nav class="catagory-listing list-unstyled">
                        <b-nav-item @click="selectOffer" v-if="offers_data != null && offers_data.status!=0" :class="selected_category_name == 'Offers' ? 'sidebar-text-color active' : ''">Offers</b-nav-item>
                        <template v-for="(categry,index) in categoryPaginatedData()">
                          <b-nav-item-dropdown v-bind:key="index"  :class="categry.category_name == selected_category_name ? 'active' : ''" v-if="categry.sub_categories != null && categry.category_name != 'Best Seller' && filterSubCategory(categry.sub_categories).length > 1">
                            <template slot="button-content" >
                              <span class="fullcategoryclick" :title="categry.category_name" @click="selectCategory(categry.category_id, categry.category_name, true)">{{categry.category_name | strippedCategoryname}}</span>
                              <span class="dropdownbtn" v-show="categry.sub_categories != null && categry.category_name != 'Best Seller' && filterSubCategory(categry.sub_categories).length > 1"></span>
                            </template>
                            <template v-if="categry.sub_categories != null && categry.category_name != 'Best Seller' && filterSubCategory(categry.sub_categories).length > 1">
                              <b-dropdown-item :class="subcategry.sub_category_name == selected_subcategory_name ? 'active':''" v-for="(subcategry,index1) in filterSubCategory(categry.sub_categories)" v-bind:key="index1">
                                  <span class="fullsubcategoryclick" @click="selectSubCategory(categry.category_id,subcategry.sub_category_id,categry.category_name,subcategry.sub_category_name, true)" :title="subcategry.sub_category_name">{{subcategry.sub_category_name | strippedSubCategoryname}}</span>
                              </b-dropdown-item>                                
                            </template>
                          </b-nav-item-dropdown>
                          <b-nav-item @click="selectCategory(categry.category_id, categry.category_name, true)" :class="categry.category_name == selected_category_name ? 'active' : ''" v-bind:key="index" v-else :title="categry.category_name">{{categry.category_name | strippedItemname}}</b-nav-item>
                        </template>
                    </b-nav>
                    <br>
                    <a :style="textcolor" href="javascript:void(0)" @click="showMoreCategory()" v-show="this.total_category > 15 && load_more_category == true">Load More</a>
                  </b-collapse>
                </div>
                
                <div class="shop-filter" id="ShopFilterMain" v-if="getcategorylist.status == '1' && this.offer_section == false">
                  <h2 class="section-heading mb-2" v-b-toggle.Filtercollapse>Filter</h2>
                  <b-collapse visible id="Filtercollapse">
                    <div class="text-right"><a :style="textcolor" href="javascript:void(0)" @click="clearAllFilter()">Clear all</a></div>
                    <!-- Food Type -->
                    <div class="filter-divisin mt-3" v-if="getshopdetaildata.data.store.item_type == 1">
                      <h6><b>Food Type</b></h6>
                      <div class="filter-option">
                        <form class="animated-form">
                          <div class="form-row">
                            <div class="form-group form-check col-md-12">
                              <input type="checkbox" id="Veg" v-model="food_type" value="1" @change="filterShopItem($event)">
                              <label class="form-check-label" for="Veg">Veg</label>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group form-check col-md-12">
                              <input type="checkbox" id="NonVeg" v-model="food_type" value="2" @change="filterShopItem($event)">
                              <label class="form-check-label" for="NonVeg">Non-Veg </label>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group form-check col-md-12">
                              <input type="checkbox" id="Egg" v-model="food_type" value="3" @change="filterShopItem($event)">
                              <label class="form-check-label" for="Egg">Egg</label>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <!-- Availability Type -->
                    <div class="filter-divisin mt-3" v-if="getshopdetaildata.data.store.prescription == 1">
                      <h6><b>Prescription</b></h6>
                      <div class="filter-option">
                        <form class="animated-form">
                          <div class="form-row">
                            <div class="form-group form-check col-md-12">
                              <input type="checkbox" id="PreNotRequired" v-model="prescription" value="0" @change="filterShopItem($event)">
                              <label class="form-check-label" for="PreNotRequired">Not Required</label>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group form-check col-md-12">
                              <input type="checkbox" id="PreRequired" v-model="prescription" value="1" @change="filterShopItem($event)">
                              <label class="form-check-label" for="PreRequired">Required</label>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <!-- Availability Type -->
                    <div class="filter-divisin mt-3">
                      <h6><b>Availability</b></h6>
                      <div class="filter-option">
                        <form class="animated-form">
                          <div class="form-row">
                            <div class="form-group form-check col-md-12">
                              <input type="checkbox" id="InStockItems" v-model="out_of_stock" @change="filterShopItem($event)">
                              <label class="form-check-label" for="InStockItems">In Stock Items</label>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>


                    <!-- Category Type -->
                    <div class="filter-divisin mt-3" v-if="category_items_filter != null && category_items_filter != undefined && category_items_filter.length > 0">
                      <h6><b>Category</b></h6>
                      <div class="filter-option">
                        <form @submit.prevent="categoryFilter()" class="animated-form">
                          <div class="form-row">
                            <div class="form-group col-12 mb-3">
                              <label for="searchbar" class="sr-only">search</label>
                              <input type="text" class="form-control" @keyup="categoryFilter()" v-model="categorysearch" id="searchbar" placeholder="Search for category">
                              <span class="category-filter-clear" v-if="categorysearch != ''" @click="clearCategoryFilter()" style="cursor: pointer;">X</span>
                              <button type="button"  class="btn button-style hoverEffect search-btn" :style="colorObj">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="123.295 0 25.001 25" enable-background="new 123.295 0 25.001 25" xml:space="preserve">
                                <g>
                                  <g>
                                    <g>
                                      <path fill="#fff" d="M134.303,0c-6.069,0-11.008,4.938-11.008,11.01c0,6.07,4.938,11.01,11.008,11.01
                                      c6.072,0,11.012-4.938,11.012-11.01C145.314,4.938,140.375,0,134.303,0z M134.303,19.986c-4.948,0-8.977-4.027-8.977-8.978
                                      s4.028-8.978,8.977-8.978c4.951,0,8.979,4.027,8.979,8.978C143.279,15.959,139.254,19.986,134.303,19.986z"/>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <path fill="#fff" d="M147.997,23.266l-5.827-5.828c-0.396-0.396-1.041-0.396-1.436,0c-0.398,0.398-0.398,1.041,0,1.438
                                      l5.824,5.826C146.76,24.9,147.018,25,147.279,25c0.259,0,0.52-0.1,0.718-0.298C148.395,24.307,148.395,23.662,147.997,23.266z"/>
                                    </g>
                                  </g>
                                </g>
                                </svg>
                              </button> 
                            </div>
                          </div>
                          <div class="form-row" v-for="(category, index) in filteredcategory_items" v-bind:key="index">
                            <div class="form-group form-check col-md-12" v-if="index < category_limit_by">
                              <input type="checkbox" :id="category.category_name" v-model="category_selection" :value="category.category_id" @change="filterShopItemForBrandAndCategory()">
                              <label class="form-check-label" :for="category.category_name"> {{category.category_name}}</label>
                            </div>
                          </div>
                          <a href="javascript:void(0)" :style="textcolor" class="mt-1" v-if="filteredcategory_items.length > 3" @click="category_toggle(category_default_limit, filteredcategory_items.length)">{{ category_limit_by === 3 ? 'Show more': 'Show less'}}</a>
                        </form>
                      </div>
                    </div>


                    <!-- Food Type -->
                    <div class="filter-divisin mt-3" v-if="brand_items != null && brand_items != undefined && brand_items.length > 0 && category_items.length > 0">
                      <h6><b>Brand</b></h6>
                      <div class="filter-option">
                        <form @submit.prevent="brandFilter()" class="animated-form">
                          <div class="form-row">
                            <div class="form-group col-12 mb-3">
                              <label for="searchbar" class="sr-only">search</label>
                              <input type="text" class="form-control" @keyup="brandFilter()" v-model="brandsearch" id="searchbrand" placeholder="Search for brand">
                              <span class="category-filter-clear" v-if="brandsearch != ''" @click="clearBrandFilter()" style="cursor: pointer;">X</span>
                              <button type="button"  class="btn button-style hoverEffect search-btn" :style="colorObj">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="123.295 0 25.001 25" enable-background="new 123.295 0 25.001 25" xml:space="preserve">
                                <g>
                                  <g>
                                    <g>
                                      <path fill="#fff" d="M134.303,0c-6.069,0-11.008,4.938-11.008,11.01c0,6.07,4.938,11.01,11.008,11.01
                                      c6.072,0,11.012-4.938,11.012-11.01C145.314,4.938,140.375,0,134.303,0z M134.303,19.986c-4.948,0-8.977-4.027-8.977-8.978
                                      s4.028-8.978,8.977-8.978c4.951,0,8.979,4.027,8.979,8.978C143.279,15.959,139.254,19.986,134.303,19.986z"/>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <path fill="#fff" d="M147.997,23.266l-5.827-5.828c-0.396-0.396-1.041-0.396-1.436,0c-0.398,0.398-0.398,1.041,0,1.438
                                      l5.824,5.826C146.76,24.9,147.018,25,147.279,25c0.259,0,0.52-0.1,0.718-0.298C148.395,24.307,148.395,23.662,147.997,23.266z"/>
                                    </g>
                                  </g>
                                </g>
                                </svg>
                              </button> 
                            </div>
                          </div>
                          <div class="form-row" v-for="(brand, index) in filteredbrand_items" v-bind:key="index">
                            <div class="form-group form-check col-md-12" v-if="index < brand_limit_by">
                              
                              <input type="checkbox" :id="brand" v-model="brand_selection" :value="brand" @change="filterShopItemForBrandAndCategory()" checked>
                              <label class="form-check-label" :for="brand"> {{brand}}</label>
                            </div>
                          </div>
                          <a href="javascript:void(0)" :style="textcolor" class="mt-1" v-if="filteredbrand_items.length > 3" @click="brand_toggle(brand_default_limit, filteredbrand_items.length)">{{ brand_limit_by === 3 ? 'Show more': 'Show less'}}</a>
                        </form>
                      </div>
                    </div>

                    <!-- Price -->
                    <div class="filter-divisin mt-3" v-if="filter_data.max_price != 0 && category_items.length > 0">
                      <h6><b>Price</b></h6>
                      <div class="filter-option">
                        <div class="pl-3 price-slider">
                          <vue-slider ref="price_range_slider" v-model="price_range" :show="true" :enable-cross="false" :adsorb="true" :min="filter_data_min_price" :max="filter_data_max_price" :disabled="(filter_data_min_price == filter_data_max_price) ? true : false"
                          :tooltip-formatter="currency_symbol+'{value}'" @drag-end="filterShopItem($event)" :clickable="false" :silent="true" :useKeyboard="false"></vue-slider>
                        </div>
                      </div>
                    </div>
                    <br>

                    <!-- Discount -->
                    <div class="filter-divisin mt-3" v-if="filter_data.max_discount != 0 && category_items.length > 0">
                      <h6><b>Discount</b></h6>
                      <div class="filter-option">
                        <div class="pl-3 price-slider">
                          <vue-slider ref="discount_range_slider" v-model="discount_range" :show="true" :enable-cross="false" :min="filter_data_min_discount" :max="filter_data_max_discount" :disabled="(filter_data_min_discount == filter_data_max_discount) ? true : false" 
                          :tooltip-formatter="'{value}%'" @drag-end="filterShopItem($event)" :clickable="false" :silent="true" :useKeyboard="false"></vue-slider>
                        </div>
                      </div>
                      <br><br>
                    </div>
                  </b-collapse>
                </div>
            </div>
            </div>
          </div>

          <div class="col col-md shop-row" v-if="loading == false">
            <div class="tabing-section">
              <!-- Offer Section Start -->
              <div v-if="offer_section == true && categroy_section == false" class="tabbing offer active" id="offer_section">
                <div v-if="offers_data != null && offers_data.status == '1'">
                  <div class="fixed-heading d-flex justify-content-between">
                    <h2 id="offer-section-heading" class="section-heading mb-2">Offers</h2>
                  </div>
                  <div class="row offer-row">
                    <div class="col-xl-6"  v-for="(offer_list, index) in offerPaginatedData" v-bind:key="index">
                      <div class="item" :style="color_code_list[index]">
                        <div class="offer-box">
                          <div class="profile-id media">
                            <img
                              :src="offer_list.store_logo"
                              class="mr-3 rounded-circle"
                              alt="..."
                              height="100"
                              width="100"
                              v-if="offer_list.store_logo != ''"
                            >
                            <avatar
                              :fullname="offer_list.store_name.split(' ')[0]"
                              :size="96"
                              v-if="offer_list.store_logo == ''"
                              class="mr-3 rounded-circle"
                            ></avatar>
                            <div class="media-body">
                              <h5>{{ offer_list.store_name }}</h5>
                              <h6>{{ offer_list.category_name }}</h6>
                              <a class="location" href="#">
                                <img
                                  src="../../assets/images/location-icon.png"
                                  alt="location-icon.png"
                                >
                                {{ offer_list.area_name }}
                              </a>
                            </div>
                          </div>
                          <div class="offer-description">
                            <h4 v-if="offer_list.title!= null" :title="offer_list.title">
                              {{ offer_list.title | strippedOfferTitle }}
                            </h4>
                            <p v-if="offer_list.validity!= null" :title="offer_list.validity">
                              Validity Period: {{ offer_list.validity }}
                            </p>
                            <p v-if="offer_list.minimum_value!= null" :title="offer_list.minimum_value">
                              {{ offer_list.minimum_value | strippedMinValue }}
                            </p>
                          </div>
                          <div :class="offer_list.discount_code ? 'row mx-0 align-items-center' : 'row align-items-center'">
                            <div
                              class="promocode"
                              id="discountcode"
                              v-clipboard:copy="offer_list.discount_code"
                              v-clipboard:success="clipboardSuccessHandler"
                              style="cursor:pointer"
                              v-if="offer_list.discount_code"
                            >{{ offer_list.discount_code }}</div>
                            <a
                              href="javascript:void(0)"
                              class="detail-btn"
                              @click="viewOfferDetail(offer_list)"
                            >View Details</a>
                          </div>
                          <div class="icon-bg">
                            <img :src="offer_list.web_offer_icon" alt="...">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="offers_data.status == '0'">
                  <p>{{ offers_data.message }}</p>
                </div>
              </div>

              <div class="paggination" v-if="offer_section == true && categroy_section == false">
                <span v-if="offers_data.status != '0'">
                  <span v-if="offers_data != ''">
                    <p class="text-center">
                      <span v-if="offers_data.data.length > 8">
                        <button
                          :disabled="offer_page_number === 0"
                          @click="prevPageOffer"
                          :style="colorObj"
                          class="btn button-style hoverEffect"
                        >Previous</button>
                        &nbsp;
                        <button
                          :disabled="offer_page_number >= offerpageCount - 1"
                          @click="nextPageOffer"
                          :style="colorObj"
                          class="btn button-style hoverEffect"
                        >Next</button>
                      </span>
                    </p>
                  </span>
                </span>
              </div>
              <!-- Offer Section End -->

              <!-- Category Item Section Start -->
              <div v-if="categroy_section == true && offer_section == false" class="tabbing bestshop active" id="category_section">
                <div class="fixed-heading d-flex justify-content-between">
                    <h2 id="product-section-heading" class="section-heading mb-2" v-if="searchproduct == '' || issearch == false">{{ selected_category_name }}</h2>
                    <h2 class="section-heading mb-2" v-if="searchproduct != '' && issearch == true">Search result(s) for {{ searchproduct }}</h2>
                    <span :class="show_clear_search ? 'toggle-view-option toggle-view-option-list-view':'toggle-view-option d-flex align-items-center'">
                      <span title='Clear Filter' type="button" v-if="food_type.length > 0 ||  brand_selection.length > 0 || out_of_stock == true || prescription.length > 0 || price_range[0] > filter_data.min_price || price_range[1] < filter_data.max_price || discount_range[0] > filter_data.min_discount || discount_range[1] < filter_data.max_discount" @click="clearAllFilter()" class="clear_filter_search">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="60px" height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                            <g>
                              <path class="st0" d="M31.2,23.3L31,23.6c-0.5,0.6-0.8,1.3-0.8,2.1v14c0,1-0.5,1.5-1.3,2.5l-6.3,7.3c-0.6,0.6-1.6,0.6-2.2,0   c-0.3-0.3-0.5-0.7-0.5-1.1V25.7c0-0.8-0.3-1.5-0.9-2.1L0.8,3.9C0,2.9,0,1.5,1,0.6C1.4,0.2,2,0,2.5,0h35c-6.9,0-12.5,5.6-12.5,12.5   C25.1,17,27.4,21.1,31.2,23.3L31.2,23.3z"/>
                              <path class="st0" d="M46.8,4.7c-4.3-4.3-11.3-4.3-15.6,0s-4.3,11.3,0,15.6c4.3,4.3,11.3,4.3,15.6,0C51.1,16,51.1,9,46.8,4.7   C46.8,4.7,46.8,4.7,46.8,4.7z M43.5,15.7c0.4,0.4,0.4,0.9,0,1.3c-0.2,0.2-0.4,0.3-0.7,0.3c-0.2,0-0.5-0.1-0.7-0.3L39,13.8L35.8,17   c-0.3,0.4-0.9,0.4-1.3,0.1c-0.4-0.3-0.4-0.9-0.1-1.3c0,0,0,0,0.1-0.1l3.2-3.2l-3.2-3.2C34,9,34,8.4,34.2,8s0.9-0.5,1.3-0.2   c0.1,0.1,0.2,0.1,0.2,0.2l3.2,3.2L42.2,8c0.3-0.4,0.9-0.4,1.3-0.1c0.4,0.3,0.4,0.9,0.1,1.3c0,0,0,0-0.1,0.1l-3.2,3.2L43.5,15.7z"/>
                            </g>
                          </svg>
                      </span>
                    </span>
                </div>

                <div v-if="category_items.length > 0">
                  <div :class="(showListView) ? 'row seller-row list-view':'row seller-row'">
                    <div
                      :class="showListView ?'col-md-6 col-lg-4 col-xl-4 list-view-box':'col-md-6 col-lg-4 col-xl-4'"
                      v-for="(product, index) in itemPaginatedData"
                      v-bind:key="index"
                    >
                      <div class="product-main">
                        <div class="product-rating" v-if="(review_rating_module == 1) ? (product.avg_rating != '' && product.avg_rating != null && show_review_rating == 1) : false" :style="{ backgroundColor:default_rating_bg_color , color: default_rating_color_code_text}">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 19.23 18.29">
                            <path id="Path_86" data-name="Path 86" d="M113,1843.854l2.971,6.021,6.645.965-4.808,4.687,1.136,6.617L113,1859.021l-5.943,3.124,1.135-6.617-4.807-4.687,6.643-.965Z" transform="translate(-103.385 -1843.854)" :fill="default_rating_color_code_text"/>
                          </svg>
                          <span class="ml-1">{{product.avg_rating}}</span>
                        </div>
                        <div class="product-rating" v-if="(review_rating_module == 1) && product.avg_rating <=show_rating_different_color_code ? (product.avg_rating != '' && product.avg_rating != null && show_review_rating == 1) : false" :style="{ backgroundColor:alternet_bg_color , color: alternate_color_code_text}">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 19.23 18.29">
                            <path id="Path_86" data-name="Path 86" d="M113,1843.854l2.971,6.021,6.645.965-4.808,4.687,1.136,6.617L113,1859.021l-5.943,3.124,1.135-6.617-4.807-4.687,6.643-.965Z" transform="translate(-103.385 -1843.854)" :fill="alternate_color_code_text"/>
                          </svg>
                          <span class="ml-1">{{product.avg_rating}}</span>
                        </div>
                        <div class="product-rating" v-if="(review_rating_module == 1 && show_rating == 1) ? (product.avg_rating == null && show_review_rating == 1) : false" :style="{ backgroundColor:default_rating_bg_color , color: default_rating_color_code_text}">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 19.23 18.29">
                            <path id="Path_86" data-name="Path 86" d="M113,1843.854l2.971,6.021,6.645.965-4.808,4.687,1.136,6.617L113,1859.021l-5.943,3.124,1.135-6.617-4.807-4.687,6.643-.965Z" transform="translate(-103.385 -1843.854)" :fill="default_rating_color_code_text"/>
                          </svg>
                          <span class="ml-1">5.0</span>
                        </div>
                        <div class="top-box">

                          <div class="product-image">
                            <a :href="'/productdetail?itemid=' + product.item_id" class="d-block">
                              <v-lazy-image :src="product.item_image" src-placeholder="product.item_image" width="150" height="150" />
                            </a>
                          </div>

                          <div class="product-wrap-box">
                            <div class="middle-box">
                              <div class="product-type-category" v-if="product.item_type == '1'">
                                <img src="../../assets/images/svgs/veg.svg" height="20" width="20">
                              </div>
                              <div class="product-type-category" v-if="product.item_type == '2'">
                                <img src="../../assets/images/svgs/nonveg.svg" height="20" width="20">
                              </div>
                              <div class="product-type-category" v-if="product.item_type == '3'">
                                <img src="../../assets/images/svgs/egg.svg" height="20" width="20">
                              </div>
                              <div v-if="product.mask_price == 1" class="price row mx-0 justify-content-between align-items-center">
                                <h5 class="contact-store">Contact store for price</h5>
                              </div>

                              <div v-else class="price row mx-0 justify-content-between align-items-center">
                                <p class="main-price mr-1">
                                  <span v-html="priceFormatter(getItemDiscountedPrice(product, index))"></span>
                                </p>
                                <p class="delet-price" v-if="getItemPrice(product, index) != '0'">
                                  <span>
                                    <span v-html="priceFormatter(getItemPrice(product, index))"></span>
                                  </span>
                                </p>
                              </div>
                              <h5 class="brand-name" v-if="product.brand_name != ''">{{ product.brand_name }}</h5>
                              <a 
                                href="javascript:void(0);"
                                :title=product.item_name
                                class="product-label"
                                v-if="showListView && !isMobile()"
                              >{{product.item_name | strippedProductname}}</a>
                              <a 
                                href="javascript:void(0);"
                                :title=product.item_name
                                class="product-label"
                                v-else
                              >{{product.item_name | strippedItemname }}</a>
                              <h5
                                :style="textcolor"
                                v-if="getChoiceAddon(product.item_variants[0], 0) != null && getChoiceAddon(product.item_variants[0], 0) == '0'"
                                class="available-label"
                              >Choices/Addons Available</h5>
                              <h5
                                :style="textcolor"
                                v-if="getChoiceAddon(product.item_variants[0], 1) != null && getChoiceAddon(product.item_variants[0], 1) == '1'"
                                class="available-label"
                              >Choices Available</h5>
                              <h5
                                :style="textcolor"
                                v-if="getChoiceAddon(product.item_variants[0], 2) != null && getChoiceAddon(product.item_variants[0], 2) == '2'"
                                class="available-label"
                              >Addons Available</h5>
                              <h5 v-if="product.prescription == 1" class="prescription-required" style="color:#FF3333">
                                Prescription required
                              </h5>

                              <h5 :title="product.combo_description" v-if="product.is_combo == 1 && product.combo_description != null">
                                {{product.combo_description}}
                              </h5>
                              <h5 v-if="product.item_variants.length == 1">
                                <span v-if="parseFloat(product.item_variants[0].unit_quantity) != '0'">
                                  {{ Number( product.item_variants[0].unit_quantity ).toFixed(1) }}
                                </span>
                                <span v-if="product.item_variants[0].unit_name != null">
                                  {{ product.item_variants[0].unit_name }}
                                  <span v-if="product.item_variants[0].min_qty > 0">
                                    (Min {{ product.item_variants[0].min_qty }} - Max {{ product.item_variants[0].max_qty }})
                                  </span>
                                </span>
                                <span v-if="product.item_variants[0].packaging_name != null">
                                  {{ product.item_variants[0].packaging_name }}
                                </span>
                                <input type="hidden" v-model="itemVariantData[index].item_variants_id">
                              </h5>
                              <select 
                                v-else
                                class="form-control custmzdropdwn"
                                v-model="itemVariantData[index].item_variants_id"
                              >
                                <option
                                  v-for="(variant,index) in product.item_variants"
                                  v-bind:value="variant.item_variants_id"
                                  v-bind:key="index"
                                >
                                  <span v-if="parseFloat(variant.unit_quantity) != '0'">
                                    {{ Number( variant.unit_quantity ).toFixed(1) }}
                                  </span>
                                  <span v-if="variant.unit_name != null">
                                    {{ variant.unit_name }}
                                  </span>
                                  <span v-if="variant.packaging_name != null">
                                    {{ variant.packaging_name }}
                                  </span>
                                  <span v-if="variant.min_qty > 0">
                                    (Min {{ variant.min_qty }} - Max {{ variant.max_qty }})
                                  </span>
                                </option>
                              </select>
                              
                              <p class="product-short-info" :title="product.item_description" v-if="showListView">{{ product.item_description | descriptionOfListViewItem }}</p>
                              <p class="product-short-info" :title="product.item_description" v-else>{{ product.item_description | descriptionOfGridViewItem }}</p>
                            </div>
                          </div>
                        </div>
                        <div class="bottom-box" v-if="product.out_of_stock==0">
                            <div class="bottom-row row mx-0 quentity-selection" :id="'newaddcartitmvrtn' + product.item_id" :style="isItemAdded(product, index) == true ? 'display:inline !important;' : 'display:none;'">
                              <div class="quentity-selector">
                                <div class="number quantity-manage row mx-0">
                                  <span class="minus" :style="textcolor" @click="removeQuantity(product, index)">-</span>
                                  <input
                                    type="text"
                                    :style="textcolor"
                                    v-bind:value="getItemQuantity(product, index)"
                                    :name="'quantityid' + product.item_id"
                                    :id="'quantityid' + product.item_id"
                                    @keypress="onlyNumber"
                                  >
                                  <span class="plus" :style="textcolor" @click="addQuantity(product, index)">+</span>
                                </div>
                              </div>
                              <input
                                type="hidden"
                                name="itemvarintid"
                                :id="'itemvarintid' + product.item_id"
                                :value="getItemVarintId(product, index)"
                              >
                            </div>
                            <div class="bottom-row row mx-0">
                              <div
                                class="button"
                                :id="'addcartitmvrtn' + product.item_id"
                                :style="isItemAdded(product, index) === false  ? 'display:inline;' : 'display:none;'"
                              >
                                <input
                                  type="hidden"
                                  name="itemvarintid"
                                  :id="'itemvarintid' + product.item_id"
                                  :value="getItemVarintId(product, index)"
                                >
                                <a 
                                  v-if="showListView"
                                  :style="{color:addtextColor, borderColor:addtextColor}"
                                  class="btn button-style"
                                  :class="disableAddButton == false ? 'disabled disable-input': ''"
                                  @mouseover="changeColorOnHover()"
                                  @mouseleave="changeColorOnLeave()"
                                  @click="addToWishlist(product,index)"
                                > Add 
                                <span class="plus-sign d-none">+</span>
                                </a>
                                <a 
                                  v-else
                                  :style="colorObj"
                                  :class="disableAddButton == false ? 'disabled disable-input': ''"
                                  class="btn button-style hoverEffect"
                                  @click="addToWishlist(product,index)"
                                > Add 
                                <span class="plus-sign d-none">+</span>
                                </a>
                              </div>
                            </div>
                        </div>
                        <div class="list-view-border d-none d-md-block"></div>
                        <div class="bottom-box" v-if="product.out_of_stock==1 && showButton">
                          <div class="bottom-row row mx-0 quentity-selection" :id="'newaddcartitmvrtn' + product.item_id" :style="isItemAdded(product, index) === true  && showButton ? 'display:inline;' : 'display:none;'">
                              <div class="quentity-selector">
                                <div class="number quantity-manage row mx-0">
                                  <span class="minus" :style="textcolor" @click="removeQuantity(product, index)">-</span>
                                  <input
                                    type="text"
                                    :style="textcolor"
                                    v-bind:value="getItemQuantity(product, index)"
                                    :name="'quantityid' + product.item_id"
                                    :id="'quantityid' + product.item_id"
                                    @keypress="onlyNumber"
                                  >
                                  <span class="plus" :style="textcolor" @click="addQuantity(product, index)">+</span>
                                </div>
                              </div>
                              <input
                                type="hidden"
                                name="itemvarintid"
                                :id="'itemvarintid' + product.item_id"
                                :value="getItemVarintId(product, index)"
                              >
                            </div>
                          <div class="bottom-row row mx-0">
                            <div class="button"
                              :id="'addcartitmvrtn' + product.item_id"
                              :style="isItemAdded(product, index) === false  ? 'display:inline;' : 'display:none;'">
                              <input
                                type="hidden"
                                name="itemvarintid"
                                :id="'itemvarintid' + product.item_id"
                                :value="getItemVarintId(product, index)"
                              >
                            <a
                              style="cursor: pointer;"
                              :style="{color:addtextColor, borderColor:addtextColor}"
                              :id="'btnnotifyme' + product.item_id"
                              @mouseover="changeColorOnHover"
                              @mouseleave="changeColorOnLeave"
                              @click="addToWishlist(product,index)"
                            >ADD</a>
                          </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="modal fade"
                        id="shareStoreModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="shareStoreModal"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="shareStoreModalLabel">Share Store</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <div class="networksocialmediashare text-center">                                
                              <ShareNetwork network="facebook" :url=dynamicFacebookUrl>
                                <img class="socialmediashare" src="../../assets/images/svgs/facebookshare.svg" title="Facebook" />
                              </ShareNetwork>
                              <ShareNetwork network="twitter" :url=dynamicFacebookUrl>
                                <img class="socialmediashare" src="../../assets/images/svgs/twittershare.svg" title="Twitter" />
                              </ShareNetwork>
                              <ShareNetwork network="whatsapp" :url=dynamicFacebookUrl>
                                <img class="socialmediashare" src="../../assets/images/svgs/whatsappshare.svg" title="Whatsapp" />
                              </ShareNetwork>
                              <!-- <ShareNetwork network="skype" :url=dynamicFacebookUrl>
                                <img class="socialmediashare" src="../../assets/images/svgs/skypeshare.svg" title="Skype" />
                              </ShareNetwork> -->
                              <ShareNetwork network="pinterest" :url=dynamicFacebookUrl>
                                <img class="socialmediashare" src="../../assets/images/svgs/pinterestshare.svg" title="Pinterest" />
                              </ShareNetwork>
                              <ShareNetwork network="email" :url=dynamicFacebookUrl>
                                <img class="socialmediashare" src="../../assets/images/svgs/emailshare.svg" title="Email" />
                              </ShareNetwork>
                              <!-- <ShareNetwork network="linkedin" :url=dynamicFacebookUrl>
                                <img class="socialmediashare" src="../../assets/images/svgs/linkedinshare.svg" title="Linkedin" />
                              </ShareNetwork> -->
                              </div>
                              <div class="copytoclipboard text-center">
                              <button v-clipboard:copy="dynamicFacebookUrl" v-clipboard:success="clipboardSocialSuccessHandler" :style="colorObj" class="btn repeat-btn hoverEffect">Copy to clipboard</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="modal fade"
                        :id="'ReplaceCartModal'+index"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="ReplaceCartModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="ReplaceCartModalLabel">Replace cart item(s)?</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">{{ cart_message }}</div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                              <button type="button" :style="colorObj" class="btn repeat-btn hoverEffect" @click="removeCart(product, index)">Yes</button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                          class="modal fade"
                          :id="'QuantityAlertModal'+index"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="QuantityAlertModalLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="QuantityAlertModalLabel">Quantity Alert</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">{{ qty_alert_message }}</div>
                              <div class="modal-footer">
                                <button v-if="is_min_qty" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button v-if="is_min_qty" type="button" :style="colorObj" class="btn btn-primary hoverEffect" @click="(remove_min_qty == true) ? removeMinQty(product, index) : addMinQty(product, index)">Yes</button>
                                <button v-else type="button" class="btn btn-primary" data-dismiss="modal">OK</button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="modal fade repeat-alert-modal"
                          :id="'RepeatAlertModal'+index"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="RepeatAlertModalLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="RepeatAlertModalLabel" v-if="repeat_addon_choice != null">
                                    {{repeat_addon_choice.item_name}}
                                    <p>Repeat last used choices/addons?</p>
                                </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body" v-if="repeat_addon_choice != null">
                                <h6>
                                  <span v-for="(choice_addon, ind) in repeat_addon_choice.choice_addon_details" v-bind:key="ind">
                                    <span>{{choice_addon}}</span><br>
                                  </span>
                                </h6>
                                <h5><span v-html="priceFormatter(repeat_addon_choice.discounted_price)"></span></h5>
                              </div>
                              <div class="modal-footer">
                                <button type="button" :style="colorObj" class="btn repeat-btn hoverEffect" @click="repeatItemAddonChoices(product, index)">Repeat</button>
                                <button type="button" :style="colorObj" class="btn add-new-btn hoverEffect" @click="addNewItemAddonChoices(product, index)">Add New</button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="modal fade repeat-alert-modal"
                          :id="'RepeatComboAlertModal'+index"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="RepeatComboAlertModalLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="RepeatComboAlertModalLabel" v-if="repeat_combo != null">
                                    {{repeat_combo.item_name}}
                                    <p>Repeat the combo choices?</p>
                                </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body" v-if="repeat_combo != null">
                                <h6>
                                  <span v-for="(combo, ind) in repeat_combo.combo_variants" v-bind:key="ind">
                                    <span>{{combo.combo_variant_name}}</span><br>
                                    <span class="ml-4" v-if="parseFloat(combo.unit_quantity) != '0' || combo.unit_name != null || combo.packaging_name != null">
                                        <span v-if="parseFloat(combo.unit_quantity) != '0'">
                                          {{ Number( combo.unit_quantity ).toFixed(1) }}
                                        </span>
                                        <span v-if="combo.unit_name != null">
                                          {{ combo.unit_name }}
                                        </span>
                                        <span v-if="combo.packaging_name != null">
                                          {{ combo.packaging_name }}
                                        </span>
                                        <br>
                                    </span>
                                    <h6 class="w-100 mb-2 text-black" v-if="combo.choices != null">
                                      <span v-for="(choice,ind) in filterComboChoices(combo.choices)" v-bind:key="ind">
                                        <span v-if="(ind == 0)">{{choice.variant_choice_name}}: </span>
                                        {{choice.variant_sub_choice_name}}<template v-if="ind + 1 < filterComboChoices(combo.choices).length">, </template> 
                                      </span>
                                    </h6>
                                    <h6 class="w-100 mb-2 text-black" v-if="combo.addons != null">
                                      <span v-for="(addon,ind) in filterComboAddons(combo.addons)" v-bind:key="ind">
                                        <span v-if="(ind == 0)">{{addon.variant_addon_name}}: </span>
                                        {{addon.variant_sub_addon_name}}<template v-if="ind + 1 < filterComboAddons(combo.addons).length">, </template> 
                                      </span>
                                    </h6>
                                  </span>
                                </h6>
                                <br>
                                <h5><span v-html="priceFormatter(repeat_combo.discounted_price)"></span></h5>
                              </div>
                              <div class="modal-footer">
                                <button type="button" :style="colorObj" class="btn repeat-btn hoverEffect" @click="repeatItemCombo(product, index)">Repeat</button>
                                <button type="button" :style="colorObj" class="btn add-new-btn hoverEffect" @click="addNewItemCombo(product, index)">Add New</button>
                              </div>
                            </div>
                          </div>
                        </div>

                    </div>
                  </div>
                </div>
                <div v-if="category_items.length <= 0" class="mt-3">
                    <p>No records found</p>
                </div>
              </div>

              <div class="paggination" v-if="categroy_section == true && offer_section == false">
                <p class="text-center" v-if="category_items.length > 0">
                  <span v-if="category_items.length >= 15 || page_number > 1">
                    <button
                      v-show="pageCount > 1"
                      :disabled="page_number === 1"
                      @click="prevPage"
                      :style="colorObj"
                      class="btn btn-secondary hoverEffect"
                    >Previous</button>
                    &nbsp;
                    <button
                      v-show="pageCount > 1"
                      :disabled="page_number >= pageCount || category_items.length < 15"
                      @click="nextPage"
                      :style="colorObj"
                      class="btn button-style hoverEffect"
                    >Next</button>
                  </span>
                </p>
              </div>
              <!-- Category Item Section End -->

            </div>
          </div>

          <div class="col col-md shop-row" v-if="loading == true">
            <p class="text-center"><img src="../../assets/images/loading.gif"></p>
          </div>

        </div>
      </div>

      <div class="catagory-toggle-btn media align-items-center justify-content-center" > 
        <div id="CatagoryToggle" class="togge-button" @click="showCatagoryToggle()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fff" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu">
            <line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
          <div class="title">{{ getshopdetaildata.data != undefined ? (((getshopdetaildata.data.store.category_label).length > 7) ? (getshopdetaildata.data.store.category_label).slice(0,7) + "..." : getshopdetaildata.data.store.category_label) : '' }}</div>
        </div>
        
        <div id="FilterBtn" class="togge-button" @click="showFilterToggle()"> 
          <svg height="24px" viewBox="-4 0 393 393.99003" width="24px" xmlns="http://www.w3.org/2000/svg">
            <path :fill="filterSvgColor" stroke="currentColor" d="m368.3125 0h-351.261719c-6.195312-.0117188-11.875 3.449219-14.707031 8.960938-2.871094 5.585937-2.3671875 12.3125 1.300781 17.414062l128.6875 181.28125c.042969.0625.089844.121094.132813.183594 4.675781 6.3125 7.203125 13.957031 7.21875 21.816406v147.796875c-.027344 4.378906 1.691406 8.582031 4.777344 11.6875 3.085937 3.105469 7.28125 4.847656 11.65625 4.847656 2.226562 0 4.425781-.445312 6.480468-1.296875l72.3125-27.574218c6.480469-1.976563 10.78125-8.089844 10.78125-15.453126v-120.007812c.011719-7.855469 2.542969-15.503906 7.214844-21.816406.042969-.0625.089844-.121094.132812-.183594l128.683594-181.289062c3.667969-5.097657 4.171875-11.820313 1.300782-17.40625-2.832032-5.511719-8.511719-8.9726568-14.710938-8.960938zm-131.53125 195.992188c-7.1875 9.753906-11.074219 21.546874-11.097656 33.664062v117.578125l-66 25.164063v-142.742188c-.023438-12.117188-3.910156-23.910156-11.101563-33.664062l-124.933593-175.992188h338.070312zm0 0"/> </svg>
            <div class="title">Filter</div>
        </div>
      </div>
      <div class="filter-over-layer"></div>
    </section>
    <!-- shop section end -->
    <OfferDetail></OfferDetail>
    <CustomizeAlert pid="shop"></CustomizeAlert>
    <AvailableOption pid="shop" is_wishlist="1" @update="addToWishlistAddonChoices"></AvailableOption>
    <ComboOption pid="shop" is_wishlist="1" @update="addToWishlistComobo"  @closecombo="closeAddNewCombo"></ComboOption>
  </div>
</template>
<script>
import Vue from "vue";
import { bus } from "../../main";
import Avatar from "vue-avatar-component";
import moment from "moment-timezone";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import OfferDetail from "../offer/OfferDetail";
import AvailableOption from "../product/AvailableOption";
import ComboOption from "../product/ComboOption";
import CustomizeAlert from "../product/CustomizeAlert";
import helper from "../../store/helper";
import VLazyImage from "v-lazy-image/v2";

Vue.use(moment);
export default {
  name:"SelectWishlistItem",
  props:{
        storeid:{
            type:Number
        }
    },
  components: {
    Avatar,
    VueSlider,
    VLazyImage,
    OfferDetail,
    AvailableOption,
    ComboOption,
    CustomizeAlert,
  },
  mixins:[helper],
  data() {
    return {
      is_business_model:0,
      dynamicFacebookUrl : '',
      showButton : true,
      showListView : true,
      loading: true,
      currency_symbol: '₹',
      searchbar: "",
      userprofile: null,
      page_of_items: [],
      page_number: 1,
      getshopdetaildata: [],
      getcategorylist: [],
      category_items: [],
      selected_category_id: '',
      selected_subcategory_id: '',
      selected_category_name: '',
      selected_subcategory_name: '',
      is_search:false,
      size: 15,
      stredetail: {
        store_id: "",
        user_id: "0",
        lat: "",
        long: "",
      },
      custshopsearch: {
        store_id: "",
        user_id: "",
        search_query: "",
        page: "",
        item_type: "",
        min_price: "",
        max_price: "",
        min_discount: "",
        max_discount: "",
        brands: "",
        prescription: "",
        out_of_stock: "",
      },
      shopitemdetail: {
        store_id: "",
        category_id: "",
        user_id: "",
        sub_category_id: "",
        page: "",
        item_type: "",
        min_price: "",
        max_price: "",
        min_discount: "",
        max_discount: "",
        brands: "",
        prescription: "",
        out_of_stock: "",
      },
      categorydetail: {
        store_id: ""
      },
      custcatsubcatgry: {
        store_id: "",
        category_id: ""
      },
      custshopcatgryoffrs: {
        user_id: "",
        store_id: "",
        is_store_listing: "",
      },
      searchproduct: "",
      searchedproduct:"",
      store_id: "",
      getsubcatgrylist: "",
      getsubcatgrydetail: [],
      categroy_section: false,
      subcategory: false,
      offer_section: false,
      subcatid: "",
      sub_page_number: 0,
      subcurrentPage: 1,
      subsize: 6,
      offer_page_number: 0,
      offrcurrentPage: 1,
      offrsize: 8,
      offers_data: null,
      issearch: false,
      store_address: "",
      store_cuisines: "",
      favrtebody: {
        user_id: "",
        store_id: "",
        is_favourite: ""
      },
      category: {
        shop_category_id: "",
        shop_category_name: "",
        type: "",
      },
      default_category: {
        category_id: '',
        category_name: '',
      },
      cart_data: null,
      cart_message: "",
      get_cart_detail: {
        user_id: "",
        cart_type: "",
        timezone: ""
      },
      remove_cart: {
        user_id: "",
        cart_type: ""
      },
      add_to_cart: {
        user_id: "",
        store_id: "",
        item_id: "",
        item_variants_id: "",
        quantity: "",
        cart_type: "",
        choices: "",
        addons: "",
        combo_items: "",
        add_new: 0,
      },
      product_notify: {
        user_id: "",
        item_id: ""
      },
      local_cart: [],
      cart_response: null,
      item_variants: [],
      qty_alert_message: '',
      is_min_qty: false,
      remove_min_qty: false,
      addon_choice_cart: false,
      used_addon_choice: {
        item_id: '',
        item_name: '',
        brand_name: '',
        item_variants_id: '',
        item_price: '',
        choice_details: [],
        addon_details: [],
      },
      used_choices_addons: {
        user_id: '',
        store_id: '',
        item_variants_id: '',
      },
      repeat_addon_choice: null,
      combo_cart: false,
      used_combo: {
        item_id: '',
        item_name: '',
        brand_name: '',
        item_variants_id: '',
        item_price: '',
        choice_details: [],
        addon_details: [],
      },
      used_combo_data: {
        user_id: '',
        store_id: '',
        item_variants_id: '',
      },
      user_filter_data: {
        store_id: 0,
        selected_category_id: '',
        selected_category_name: '',
        search_query: ''
      },
       shopfiltercategory: {
        category_id: "",
        category_name: "",
        type: "",
      },
      repeat_combo: null,
      item_qty: 0,
      food_type: [],
      out_of_stock: false,
      prescription: [],
      end_cat_num: 9,
      total_category: 0,
      load_more_category: true,
      price_range: [0,0],
      filter_data_min_price: 0,
      filter_data_max_price: 0,
      discount_range: [0,0],
      filter_data_min_discount: 0,
      filter_data_max_discount: 0,
      post_data_filter_min_price: 0,
      post_data_filter_max_price: 0,
      post_data_filter_min_discount: 0,
      post_data_filter_max_discount: 0,
      currency_price_range: {},
      currency_discount_range: {},
      record_count: 0,
      show_clear_search: false,
      next_working_day: "",
      descirptionclass: 'list-view',
      combo_item_data: {
        item_id: "",
        user_id: "",
      },
      combo_item_details: null,
      selectedComboItems: [],
      addtextColor: "",
      addbuttonHover:{
        addtextColor:""
      },
      brand_selection: [],
      category_selection:[],
      brandsearch: "",
      categorysearch:"",
      filteredbrand_items: [],
      filteredcategory_items: [],
      brand_items: [],
      category_items_filter:[],
      filter_data: [],
      brand_default_limit: 3,
      brand_limit_by: 3,
      subcategorydata : {
        category_id : 0,
        category_name : "",
        subcategory_id : 0,
        subcategory_name : "",
      },
      category_default_limit: 3,
      category_limit_by: 3,
      filtered_data:{
        food_type:"",
        prescription:"",
        availability:false,
        brand:"",
        category:"",
        price:[0,0],
        min_price:"",
        max_price:"",
        discount:[0,0],
        min_discount:"",
        max_discount:"",
      },
      show_review_rating :1,
      review_rating_module : 1,
      show_business_hours:1,
      show_business_address:1,
      avatarBackgroundColor:"",
      avatarTextColor:"",
      color_code_list:[],
      add_to_wishlist:{
        item_image:"",
        item_image_thumb:"",
        item_name:"",
        item_price:"",
        item_id:"",
        item_variants_id:"",
        item_variants: [],
        quantity:"",
        is_combo:"",
        combo_variants: [],
        combo_variants_item:null,
        choices:[],
        addons:[],
      },
      default_rating_bg_color:"",
      default_rating_color_code_text:"",
      show_rating_different_color_code: 0,
      alternet_bg_color:"",
      alternate_color_code_text:"",
      show_rating:0,
      show_price_inclusive_tax:1,
      show_price_inclusive_packaging:1,
      filterSvgColor:""
    };
  },
  metaInfo() {
    return { 
        meta: [
          {
        property: 'og:title',
        content: 'Test title',
        // following template options are identical
        // template: '%s - My page',
        vmid: 'og:title'
      }
        ]
    }
  },
  filters: {
    strippedOfferTitle: function(value) {
      if (value.length > 41) {
        return value.slice(0, 40) + "...";
      }
      return value;
    },
    strippedMinValue: function(value) {
      if (value.length > 42) {
        return value.slice(0, 50) + "...";
      }
      return value;
    },
    strippedCategoryname: function(value) {
      if (value.length > 15) {
        return value.slice(0, 12) + "...";
      }
      return value;
    },
    strippedSubCategoryname: function(value) {
      if (value.length > 21) {
        return value.slice(0, 18) + "...";
      }
      return value;
    },
    strippedItemname: function(value) {
      if (value.length > 27) {
        return value.slice(0, 24) + "...";
      }
      return value;
    },
    strippedProductname: function(value) {	
      if (value.length > 43) {	
        return value.slice(0, 40) + "...";	
      }	
      return value;	
    },
    descriptionOfListViewItem: function(value){
      if(value.length >= 150){
        return value.slice(0,150) + "...";
      }
      return value;
    },
    descriptionOfGridViewItem: function(value){
      if(value.length >= 60){
        return value.slice(0,60) + "...";
      }
      return value;
    }
  },
  computed: {
    disableAddButton(){
      if (this.isStoreOpen(this.getshopdetaildata.data.business_hours) == "Closed Now" && this.getshopdetaildata.data.order_settings.accept_after_close != 1)
      {
        return !this.showButton;
      }
      else
      {
        return this.showButton
      }     
    },
    totalCartQuantity() {
      var total_qty = 0;
      if(this.cart_data != null){
        if(this.cart_data.status != 0){
          total_qty = this.cart_data.data.cart_details.quantity;
        }
      }
      return total_qty;
    },
    pageCount() {
      if (this.category_items.length > 0) {
        let l = this.record_count,
          s = this.size;
        return Math.ceil(l / s);
      } else {
        return "0";
      }
    },
    itemPaginatedData() {
      if (this.category_items.length > 0) {
        return this.category_items;
      } else {
        return [];
      }
    },
    itemVariantData() {
      if (this.item_variants.length > 0) {
        return this.item_variants;
      } else {
        return [];
      }
    },
    offerpageCount() {
      if (this.offers_data != null) {
        let l = this.offers_data.data.length,
          s = this.offrsize;
        return Math.ceil(l / s);
      } else {
        return "0";
      }
    },
    offerPaginatedData() {
      if (this.offers_data != null) {
        const start = this.offer_page_number * this.offrsize,
          end = start + this.offrsize;
        return this.offers_data.data.slice(start, end);
      } else {
        return null;
      }
    },
  },
  mounted() {
    document.body.classList.remove('top-menu-add-padding');
    document.body.classList.remove('top-navbar');
    this.dynamicFacebookUrl = window.location.href;
    this.currency_symbol = localStorage.getItem("currency_symbol") != null && localStorage.getItem("currency_symbol") != "null"?localStorage.getItem("currency_symbol"):"₹";
  
    if(localStorage.getItem("user_filter_data") != null && localStorage.getItem("ProductDetailNavigation") != null && localStorage.getItem("filtered_data")!=null)
    { 
      this.user_filter_data = JSON.parse(localStorage.getItem("user_filter_data"));
      this.filtered_data = JSON.parse(localStorage.getItem("filtered_data"));
      this.food_type = this.filtered_data.food_type;
      this.brand_selection = this.filtered_data.brand;
      this.out_of_stock = this.filtered_data.availability;
      this.prescription = this.filtered_data.prescription;
      this.price_range = this.filtered_data.price;
      this.discount_range = this.filtered_data.discount;
      if(this.user_filter_data.store_id == this.storeid)
      {
        if(this.user_filter_data.selected_category_id != 0 && this.user_filter_data.selected_category_name != "")
        {
          this.shopfiltercategory.category_id = this.user_filter_data.selected_category_id;
          this.shopfiltercategory.category_name = this.user_filter_data.selected_category_name;
          this.shopfiltercategory.type = "category";
          localStorage.setItem("selected-category", JSON.stringify(this.shopfiltercategory));
        }
        
        if(this.user_filter_data.search_query != "")
        { 
          localStorage.setItem("home-search", this.user_filter_data.search_query);
          this.category_selection = this.filtered_data.category;
        }
      }
      else{
        localStorage.removeItem("user_filter_data");
        localStorage.removeItem("filtered_data");
      }
    }
    else
    {
      this.user_filter_data.store_id=this.storeid;
      this.user_filter_data.category_id = this.selected_category_id;
      this.user_filter_data.search_query=(localStorage.getItem("home-search") != null && localStorage.getItem("home-search") != "") ? localStorage.getItem("home-search"):this.searchproduct;
      this.user_filter_data.category_name=this.selected_category_name;
      localStorage.setItem("user_filter_data", JSON.stringify(this.user_filter_data));
      
      this.filtered_data.food_type = this.food_type;
      this.filtered_data.brand = this.brand_selection;
      this.filtered_data.availability = this.out_of_stock;
      this.filtered_data.price = this.price_range;
      this.filtered_data.discount = this.discount_range;
      localStorage.setItem("filtered_data", JSON.stringify(this.filtered_data));
    }

    if(localStorage.getItem("ProductDetailNavigation") != null){
          localStorage.removeItem("ProductDetailNavigation");
    }

    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }
    
    this.getStoreDetails();
    this.getShopOffers('on-load');
    if(window.$(window).width() < 767){
      this.addtextColor = localStorage.getItem("css_button_customer_default_font")
    } else {
      this.addtextColor = localStorage.getItem("button_default_color")
    }
    this.is_business_model = localStorage.getItem("is_business_model");
    this.originalColor();
    this.removeLocalWishList();
    window.$(window).scroll(function() {
      if(window.$(this).scrollTop() > 1){
        window.$("#Togglesearchbar").hide();
      } 
      if(window.$('.shop-prifile-section').length > 0)
      {
      if(window.$(window).scrollTop() > 30 ){
        window.$('.shop-prifile-section').addClass("sticky");
        window.$('.shop-section').addClass("sticky");
        window.$(".fixed-heading").addClass("sticky");
        window.$('.shop-prifile-section.sticky .shop-banner-info .my-profile .shop-status').hide();
        window.$('.shop-prifile-section.sticky .shop-banner-info .my-profile .media-body .location').removeClass('hide-location');
        window.$('.shop-prifile-section.sticky .shop-banner-info .my-profile .media-body .location').addClass('show-location');
      }

      if(window.$(window).scrollTop() > 90 )
      {
        window.$('.shop-prifile-section').addClass("minimalize-section");
        window.$('.shop-section').addClass("minimalize-section");
        window.$(".fixed-heading").addClass("minimalize-section");
        window.$(".sticky-category").addClass("minimalize-section");
        window.$('.shop-prifile-section.sticky .shop-banner-info .my-profile .sub-title').hide();
        window.$('.shop-prifile-section.sticky .shop-banner-info .my-profile .media-body .location').addClass('hide-location'); 
        window.$('.shop-prifile-section.sticky .shop-banner-info .my-profile .media-body .location').removeClass('show-location'); 
        window.$('.shop-prifile-section.sticky .shop-offer-slider .offer-description p').hide();
        window.$('.shop-prifile-section.sticky .shop-banner-info .my-profile .shop-status').hide();

        window.$('.shop-prifile-section.sticky .shop-banner-info .order-row').hide();
        window.$('.shop-prifile-section.sticky .shop-searchbar').css("top",'-35px');        
      }
      else
      {        
        window.$('.shop-prifile-section').removeClass("minimalize-section");
        window.$('.shop-section').removeClass("minimalize-section");
        window.$(".fixed-heading").removeClass("minimalize-section");
        window.$(".sticky-category").removeClass("minimalize-section");
        window.$('.shop-prifile-section.sticky .shop-banner-info .my-profile .sub-title').show(); 
        window.$('.shop-prifile-section.sticky .shop-offer-slider .offer-description p').show();
        window.$('.shop-prifile-section.sticky .shop-banner-info .my-profile .shop-status').show();
        window.$('.shop-prifile-section.sticky .shop-banner-info .order-row').show();
        window.$('.shop-prifile-section.sticky .shop-searchbar').css("top",'10px');
      }

      if(window.$(window).scrollTop() == 0){
        window.$('.shop-prifile-section').removeClass("sticky"); 
        window.$('.shop-section').removeClass("sticky");
        window.$(".fixed-heading").removeClass("sticky");  
      }

      var headingTop = document.getElementById('category_section');
      if(window.pageYOffset > headingTop.offsetHeight){
        window.$(".fixed-heading").addClass('bottom-heading');
      } else {
        window.$(".fixed-heading").removeClass('bottom-heading');
      }
      }
    });
    
    if(window.$(window).width() < 767){
      window.$(document).ready(function(){
        window.$('.catagory-toggle-btn #CatagoryToggle').css('background-color', localStorage.getItem("button_hover_color"))
        window.$('.catagory-toggle-btn').css('color', localStorage.getItem("css_button_customer_hover_font"))
      })
    }
    window.$('<style>.shop-section .nav-item.active .nav-link { color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body');
    window.$('<style>.shop-section .nav-item .nav-link:hover { color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body');
    window.$('<style>.shop-section .dropdown-menu .active .dropdown-item { color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body');
    window.$('<style>.shop-section .dropdown-menu .dropdown-item:hover { color: '+localStorage.getItem("button_hover_color")+'; }</style>').appendTo('body');
    window.$('<style>.seller-row.list-view .product-main .button a { border-top-color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body')
    window.$('<style>.seller-row.list-view .product-main .button a { border-right-color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body')
    window.$('<style>.seller-row.list-view .product-main .button a { border-bottom-color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body')
    window.$('<style>.seller-row.list-view .product-main .button a { border-left-color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body')
    window.$('<style>.seller-row.list-view .product-main .button a:hover, .seller-row.list-view .product-main .button a:focus { background-color: '+localStorage.getItem("button_default_color")+' !important; }</style>').appendTo('body')
    window.$('<style>.seller-row.list-view .product-main .button a:hover, .seller-row.list-view .product-main .button a:focus { color: '+localStorage.getItem("css_button_customer_default_font")+' !important; }</style>').appendTo('body')
    window.$('<style>.seller-row.list-view .product-main .bottom-box .number { border-top-color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body')
    window.$('<style>.seller-row.list-view .product-main .bottom-box .number { border-right-color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body')
    window.$('<style>.seller-row.list-view .product-main .bottom-box .number { border-bottom-color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body')
    window.$('<style>.seller-row.list-view .product-main .bottom-box .number { border-left-color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body')
    window.$('<style>.available-option .radio-dt input[type="checkbox"] ~ label:before { border-top-color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body')
    window.$('<style>.available-option .radio-dt input[type="checkbox"] ~ label:before { border-right-color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body')
    window.$('<style>.available-option .radio-dt input[type="checkbox"] ~ label:before { border-bottom-color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body')
    window.$('<style>.available-option .radio-dt input[type="checkbox"] ~ label:before { border-left-color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body')
    window.$('<style>.available-option .radio-dt input[type="checkbox"] ~ label:after { background-color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body')
    this.default_rating_bg_color = localStorage.getItem("default_rating_bg_color");
    this.default_rating_color_code_text = localStorage.getItem("default_rating_text_color");
    this.show_rating_different_color_code = localStorage.getItem("show_rating_different_color_code");
    this.alternet_bg_color = localStorage.getItem("alternet_bg_color");
    this.alternate_color_code_text = localStorage.getItem("alternate_color_code_text");
    this.show_rating = localStorage.getItem("show_five_star_rating");
    bus.$on("globalsetting",data => {
      if(Object.keys(data).length != 0){
        this.review_rating_module = parseFloat(JSON.parse(data).review_rating_module);   
        this.show_price_inclusive_tax = JSON.parse(data).show_price_inclusive_tax;
        this.show_price_inclusive_packaging = JSON.parse(data).show_price_inclusive_packaging;            
        this.show_review_rating = parseFloat(JSON.parse(data).show_review_rating);
        this.show_business_hours = parseFloat(JSON.parse(data).show_store_business_hours);
        this.show_business_address = parseFloat(JSON.parse(data).show_store_address);
      }
    });
    this.filterSvgColor = localStorage.getItem("css_button_customer_default_font");
  },
  updated(){
    window.$(document).ready(function(){
      window.$('svg._1GTCc').css({ fill: localStorage.getItem("button_default_color")})
      window.$('svg g.listviewactive path').css({ fill: localStorage.getItem("button_default_color")})
      window.$('svg g.gridviewactive path').css({ fill: localStorage.getItem("button_default_color")})
      window.$('.clear_filter_search svg g path.st0').css({ fill: localStorage.getItem("button_default_color")})
      if(window.$(window).width() < 767){
        window.$('.catagory-toggle-btn #FilterBtn svg path').css({ fill: localStorage.getItem("css_button_customer_default_font")})
        window.$('.catagory-toggle-btn #CatagoryToggle').css('background-color', localStorage.getItem("button_hover_color"))
        window.$('.catagory-toggle-btn').css('color', localStorage.getItem("css_button_customer_hover_font"))
        window.$('.catagory-toggle-btn #FilterBtn').css('background-color', localStorage.getItem("button_default_color"))
        window.$('.catagory-toggle-btn #FilterBtn .title').css('color', localStorage.getItem("css_button_customer_default_font"))
        window.$('.seller-row.list-view .product-main .button a, .product-main .button a').css('background-color', localStorage.getItem("button_default_color"))
        window.$('.seller-row.list-view .product-main .button a, .product-main .button a').css('color', `'${localStorage.getItem("css_button_customer_default_font")}' !important`)
    }
    })
    window.$('<style>.comboOffer.modal .modal-dialog .nav-link.active .sr-no { background-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body')
    window.$('<style>.available-option .radio-dt input[type="radio"] ~ label:before { border-top-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body')
    window.$('<style>.available-option .radio-dt input[type="radio"] ~ label:before { border-right-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body')
    window.$('<style>.available-option .radio-dt input[type="radio"] ~ label:before { border-bottom-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body')
    window.$('<style>.available-option .radio-dt input[type="radio"] ~ label:before { border-left-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body')
    window.$('<style>.available-option .radio-dt input[type="radio"] ~ label:after { background-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body')
    window.$('<style>.comboOffer.modal .modal-dialog .tab-content .combo-result::-webkit-scrollbar-thumb { background-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body')
    window.$('<style>.vue-slider-process { background-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body')
    window.$('<style>.vue-slider-dot-tooltip-inner { color:'+localStorage.getItem("css_button_customer_default_font")+'; border-color: '+localStorage.getItem("button_default_color")+'; background-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('.avatar table tbody tr td').css({ color: this.avatarTextColor});
    this.avatarBackgroundColor = this.cancelReOrderButton.backgroundColor;
    this.avatarTextColor = this.cancelReOrderButton.color;
  },
  methods: {
    addToWishlist(item_data,index){
      if(localStorage.getItem("user") == null){
        this.openLoginfrm(item_data.item_id);
        return false;
      }
      this.add_to_wishlist.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.add_to_wishlist.item_image = item_data.item_image;
      this.add_to_wishlist.item_image_thumb = item_data.item_image_thumb;
      this.add_to_wishlist.item_name = item_data.item_name;
      this.add_to_wishlist.item_price = item_data.item_price;
      this.add_to_wishlist.item_id = item_data.item_id;
      this.add_to_wishlist.item_variants_id = parseInt(window.$("#itemvarintid" + item_data.item_id).val());
      this.add_to_wishlist.quantity = window.$("#quantityid" + item_data.item_id).val();
      this.add_to_wishlist.item_variants = item_data.item_variants;
      this.add_to_wishlist.is_combo = item_data.is_combo;
      let item_variant_index = item_data.item_variants.findIndex(
          o => o.item_variants_id == this.itemVariantData[index].item_variants_id
      );
      
      if(this.add_to_wishlist.quantity != 0){
          item_data.item_variants[item_variant_index].is_added = 1;
          item_data.item_variants[item_variant_index].quantity = this.add_to_wishlist.quantity;
      } else {
          item_data.item_variants[item_variant_index].is_added = 0;
          item_data.item_variants[item_variant_index].quantity = 1;
      }
      
      var user_wishlist = JSON.parse(localStorage.getItem("user_wishlist")) || [];
      if(localStorage.getItem("user_wishlist")!=null){
        var new_user_wishlist = this.isItemExistsInWishlist(user_wishlist, this.add_to_wishlist);
        localStorage.setItem('user_wishlist', JSON.stringify(new_user_wishlist));
        bus.$emit('add-to-wishlist', user_wishlist);
      } else {
        user_wishlist.push(JSON.parse(JSON.stringify(this.add_to_wishlist)));
        localStorage.setItem('user_wishlist', JSON.stringify(user_wishlist));
        bus.$emit('add-to-wishlist', user_wishlist);          
      }
      
      if(item_data.is_combo == 1 && this.combo_cart == false && this.add_to_wishlist.quantity!=0){
        if(this.isItemAdded(item_data, index) == true && this.add_to_wishlist.add_new == 0){
          this.repeat_combo = null;
          this.getRepeatCombo(index, parseInt(this.storeid), this.add_to_wishlist.item_variants_id);
        } else {
          this.getItemCombo(item_data,index, false);
        }
        return false;
      } else {
        this.combo_cart = false;
      }

      if(this.checkItemAddonChoice(item_data,index) != null && this.addon_choice_cart == false && this.add_to_wishlist.quantity != 0  && localStorage.getItem("user") != null){
        if(this.isItemAdded(item_data, index) == true && this.add_to_wishlist.add_new == 0){
          this.repeat_addon_choice = null;
          this.getRepeatAddonChoices(index, this.add_to_wishlist.store_id, this.add_to_wishlist.item_variants_id);
        } else {
          this.getItemAddonChoices(item_data, index, item_data.item_name);
        }
        return false;
      } else {
        this.addon_choice_cart = false;
      }
    },
    isItemExistsInWishlist(user_wishlist,wishlist_details){
      const i = user_wishlist.findIndex(o => o.item_id === wishlist_details.item_id && o.item_variants_id === wishlist_details.item_variants_id);
      if (i > -1) {
        user_wishlist[i] = wishlist_details;
        if(wishlist_details.quantity == 0){
          user_wishlist.splice(i, 1);
        }
      } else { 
        user_wishlist.push(wishlist_details);
      }
      return user_wishlist;
    },
    removeLocalWishList(){
      localStorage.removeItem("user_wishlist");
    },
    openFilter(){
      window.$('#CategoriesMain .section-heading.mb-2').removeClass('not-collapsed');
      window.$('#CategoriesMain .section-heading.mb-2').addClass('collapsed');
      window.$('#CategoriesMain .section-heading.mb-2').attr("aria-expanded","false");
      window.$('#Categoriescollapse').css("display","none");
      window.$('#Categoriescollapse').removeClass("show");
    },
    closeFilter(){
      window.$('#CategoriesMain .section-heading.mb-2').addClass('not-collapsed');
      window.$('#CategoriesMain .section-heading.mb-2').removeClass('collapsed');
      window.$('#CategoriesMain .section-heading.mb-2').attr("aria-expanded","true");
      window.$('#Categoriescollapse').css("display","block");
      window.$('#Categoriescollapse').addClass("show");
    },
    ctc(){
      window.navigator.clipboard.writeText(window.location.href);
    },
    clearFilter(){
      this.food_type = '';
      this.filter_data_min_price =this.price_range[0];
      this.filter_data_max_price = this.price_range[1];
      this.filter_data_min_discount = this.discount_range[0]; 
      this.filter_data_max_discount = this.discount_range[1];
      this.brand_selection = ''; 
      this.category_selection='';
      this.out_of_stock = '';
      this.prescription = '';
      this.getShopItemDetails();
    },
    changeColorOnHover(){
      window.$('.seller-row.list-view .product-main .button a:hover').css("background-color",localStorage.getItem("button_default_color"))
      window.$('.seller-row.list-view .product-main .button a:hover').css("color",localStorage.getItem("css_button_customer_default_font"))
    },
    changeColorOnLeave(){
      window.$('.seller-row.list-view .product-main .button a').css("background-color","#fff")
      window.$('.seller-row.list-view .product-main .button a').css("color",localStorage.getItem("button_default_color"))
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;
      if (addRemoveClass === 'addClass') {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    isMobile(){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    filterSubCategory(sub_categories){
      if(sub_categories != null){
        return sub_categories.filter(
          function(elem) {
            if (elem.count != "0") return elem;
          }
        );
      } else {
        return [];
      }
    },
    categoryPaginatedData() {
      if (this.getcategorylist != null) {
        var all_categories = [];
        this.getcategorylist.data.forEach(items => {
          if(items.count > 0) {
            all_categories.push(items);  
          }
        });
        this.total_category = all_categories.length;
        return all_categories.slice(0,all_categories.length > 14?this.end_cat_num:14);
      } else {
        return null;
      }
    },
    showMoreCategory(){
      this.end_cat_num = this.total_category;
      this.load_more_category = false;
      this.categoryPaginatedData();
    },
    onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57)) {
        $event.preventDefault();
      }
    },
    priceFormatter(value) {
      if(value){
        var price = value.toString().split(".")[0];
        var decimal = value.toString().split(".")[1];
        var response = this.currency_symbol + price;
        if(decimal != undefined){
          response += "<span class='price-decimal'><small>." + decimal + "</small></span>";
        } else {
          response += "<span class='price-decimal'><small>.00</small></span>";
        }
        return response;
      } else {
        return this.currency_symbol + '0';
      }
    },
    getStoreDetails() {
      this.stredetail.store_id = this.storeid;
      this.stredetail.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      
      if(localStorage.getItem("location_details") != null){
        if(JSON.parse(localStorage.getItem("location_details")).lat != null && JSON.parse(localStorage.getItem("location_details")).lng != null){
            this.stredetail.lat = JSON.parse(localStorage.getItem("location_details")).lat;
            this.stredetail.long = JSON.parse(localStorage.getItem("location_details")).lng;
        }
      }

      this.$store
        .dispatch("getstrepagedetail", this.stredetail)
        .then(response => {
          this.getshopdetaildata = response;
          if(this.getshopdetaildata.status == 1){
            if(parseInt(response.data.store.demo_user) == 1){
              if(parseInt(response.data.store.is_khata) == 0)
              {
                window.location.href="/";
              }
            }
            if(parseInt(response.data.store.is_redirect) == 1)
            {
              window.location.href="/";
            }
            this.store_address = this.getFilteredStoreAddress(this.getshopdetaildata.data.store);
            this.store_cuisines = this.getFilteredCuisines(this.getshopdetaildata.data.cuisines);
          }
        });
    },
    getCategoryDetails(calltype) {
      this.categorydetail.store_id = this.storeid;
      this.$store
        .dispatch("getdetailcategory", this.categorydetail)
        .then(response => {
          this.getcategorylist = response;
          if (localStorage.getItem("home-search") != null && localStorage.getItem("home-search") != "null" && localStorage.getItem("home-search") != undefined && localStorage.getItem("home-search").trim() != "") {
            this.selected_category_name = '';
            this.searchproduct = localStorage.getItem("home-search") == "null" ? "" : localStorage.getItem("home-search");
            this.postSearchItem();
            localStorage.removeItem("home-search");
            return false;
          }
          
          if(localStorage.getItem("selected-category") != null && JSON.parse(localStorage.getItem("selected-category")) != null) {
            var default_category = JSON.parse(localStorage.getItem("selected-category"));
            localStorage.setItem("default_category", JSON.stringify(default_category));
            if(default_category.category_id != undefined)
              this.selectCategory(default_category.category_id, default_category.category_name);
            localStorage.removeItem("selected-category");
          } 
          else if(localStorage.getItem("best-seller-category") != null && JSON.parse(localStorage.getItem("best-seller-category")) != null) {
            var default_bestseller_category = JSON.parse(localStorage.getItem("best-seller-category"));
            if(this.getcategorylist.data.length > 0 && default_bestseller_category.category_name != undefined){
              if(this.getcategorylist.data.find(item => item.category_name == default_bestseller_category.category_name)!=undefined){
                default_bestseller_category.category_id = this.getcategorylist.data.find(item => item.category_name == default_bestseller_category.category_name).category_id;
              }
            }
            localStorage.setItem("default_category", JSON.stringify(default_bestseller_category));
            if(default_bestseller_category.category_id != undefined)
              this.selectCategory(default_bestseller_category.category_id, default_bestseller_category.category_name);
            localStorage.removeItem("best-seller-category");
          } else if (localStorage.getItem("selected-subcategory")!=null){
            this.subcategorydata = JSON.parse(localStorage.getItem("selected-subcategory"));
            if(this.subcategorydata.subcategory_id!=null){
              this.selectSubCategory(this.subcategorydata.category_id,this.subcategorydata.subcategory_id,this.subcategorydata.category_name,this.subcategorydata.subcategory_name)
            }
            localStorage.removeItem("selected-subcategory")
          }
          else {
               if(calltype == 'on-load' && this.getcategorylist.data != undefined && this.getcategorylist.data.find(item => item.count != 0) != undefined)
               {
                 this.default_category.category_id = this.getcategorylist.data.find(item => item.count != 0).category_id;
                 this.default_category.category_name = this.getcategorylist.data.find(item => item.count != 0).category_name;
                 localStorage.setItem("default_category", JSON.stringify(this.default_category));
                 this.selectCategory(this.default_category.category_id, this.default_category.category_name);
               }
               this.loading = false;
          }
        });
    },
    getShopItemDetails() {
      this.category_items = [];
      this.item_variants = [];
      this.sub_page_number = 0;
      this.is_search = false;
      this.shopitemdetail.store_id = this.storeid;
      this.shopitemdetail.category_id = this.selected_category_id;
      this.shopitemdetail.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.shopitemdetail.sub_category_id = (this.selected_subcategory_id != "") ? this.selected_subcategory_id : null;
      this.shopitemdetail.item_type = this.food_type.length != 0 ? this.food_type.join(",") : localStorage.getItem("filtered_data")!=null ? JSON.parse(localStorage.getItem("filtered_data")).food_type.join(',') : '';
      this.shopitemdetail.min_price = this.price_range[0];
      this.shopitemdetail.max_price = this.price_range[1];
      this.shopitemdetail.min_discount = this.discount_range[0] == 0 ? localStorage.getItem("filtered_data")!=null ? JSON.parse(localStorage.getItem("filtered_data")).min_discount : 0 : this.discount_range[0];
      this.shopitemdetail.max_discount = this.discount_range[1] == 0 ? localStorage.getItem("filtered_data")!=null ? JSON.parse(localStorage.getItem("filtered_data")).max_discount : 0 : this.discount_range[1];
      this.shopitemdetail.brands =  this.brand_selection.length != 0 ? this.brand_selection.join(","):localStorage.getItem("filtered_data")!=null ? JSON.parse(localStorage.getItem("filtered_data")).brand.join(',') : '';
      this.shopitemdetail.categories =  this.category_selection.length != 0 ? this.category_selection.join(","):"";
      this.shopitemdetail.out_of_stock = (this.out_of_stock == true) ? 0 : "";
      this.shopitemdetail.prescription = this.prescription.length != 0 ? this.prescription.join(","): localStorage.getItem("filtered_data")!=null ? JSON.parse(localStorage.getItem("filtered_data")).prescription : '';
      this.shopitemdetail.page = this.page_number;
      this.loading = true;
      this.$store
        .dispatch("getshopitemdetail", this.shopitemdetail)
        .then(response => {
          var result = JSON.parse(JSON.stringify(response));
          if(result.status == 1){
            this.record_count = response.count;
            result.data.forEach(e => {
              e.items.forEach(el => {
                this.category_items.push(el);
              });
              if(this.selected_subcategory_id != ""){
                var vm = this;
                this.category_items = this.category_items.filter(
                  function(elem) {
                    if (elem.sub_category_id == vm.selected_subcategory_id) {
                      return elem;
                    }
                  }
                );
              }
            });

            if(this.category_items.length > 0) {
              this.getPreSelectedItem();
              this.category_items.forEach(e => {
                if(e.item_variants.length > 0)
                {
                 var obj = {};
                 obj['item_variants_id'] = e.item_variants[0].item_variants_id;
                 obj['quantity'] = (e.item_variants[0].quantity != null) ? e.item_variants[0].quantity : 1;
                 obj['is_added'] = 0;
                 this.item_variants.push(obj);
                }
              });
            }
            this.setFilterData(result);
          }
          if(window.$('.nav-item.show.active').length == 0 && this.selected_subcategory_id != ""){
            window.$('.nav-item.active').find('.dropdownbtn').click()
          }
          this.loading = false;
        });
    },
    getShopOffers(calltype) {
      this.custshopcatgryoffrs.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.custshopcatgryoffrs.store_id = this.storeid;
      this.custshopcatgryoffrs.is_store_listing = 1;

      this.loading = true;
      this.$store
        .dispatch("getshopcatgryoffrs", this.custshopcatgryoffrs)
        .then(response => {
          this.offers_data = JSON.parse(JSON.stringify(response));
          if (this.offers_data.status == 0) {
            this.offer_section = false;
            this.categroy_section = true;
            this.subcategory = false;
          } else {
          var offercolorcode;
          offercolorcode = localStorage.getItem("offer_color_code");
          if(offercolorcode != '' && offercolorcode != undefined){
          var globalsettingcolorcodelist = offercolorcode.split(',').length;
          var indexGlobalSettingColorCode = 0;
          for(var ofObj=0;ofObj < this.offers_data.data.length;ofObj++){
            if(indexGlobalSettingColorCode >= globalsettingcolorcodelist)
            {
              indexGlobalSettingColorCode = 0;
            }
            this.color_code_list.push(' background-color:'+offercolorcode.split(',')[indexGlobalSettingColorCode]+' !important')
            indexGlobalSettingColorCode = indexGlobalSettingColorCode + 1;
          }
          }
            if((localStorage.getItem("best-seller-category") == null || localStorage.getItem("best-seller-category") == "null") && (localStorage.getItem("selected-category") == null || localStorage.getItem("selected-category") == "null")&& localStorage.getItem("home-search") == null || (localStorage.getItem("home-search") == "null")) {
              this.selected_category_name = "Offers";
              if(calltype == 'category-click'){
                this.offer_section = true;
              } else {
                this.offer_section = false;
              }
              this.categroy_section = false;
              this.subcategory = false;
              this.loading = false;
            }
          }
          this.getCategoryDetails(calltype);
        });
    },
    searchShopItem() {
      this.category_items = [];
      this.item_variants = [];
      this.sub_page_number = 0;
      this.is_search=true;
      this.custshopsearch.store_id = this.storeid;
      this.custshopsearch.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.custshopsearch.search_query = this.searchproduct;
      this.custshopsearch.item_type = this.food_type.length != 0 ? this.food_type.join(",") : localStorage.getItem("filtered_data")!=null ? JSON.parse(localStorage.getItem("filtered_data")).food_type.join(',') : '';
      this.custshopsearch.min_price = this.price_range[0] == 0 ? localStorage.getItem("filtered_data")!=null ? JSON.parse(localStorage.getItem("filtered_data")).min_price : 0 : this.price_range[0];
      this.custshopsearch.max_price = this.price_range[1] == 0 ? localStorage.getItem("filtered_data")!=null ? JSON.parse(localStorage.getItem("filtered_data")).max_price : 0 : this.price_range[1];
      this.custshopsearch.min_discount = this.discount_range[0] == 0 ? localStorage.getItem("filtered_data")!=null ? JSON.parse(localStorage.getItem("filtered_data")).min_discount : 0 : this.discount_range[0];
      this.custshopsearch.max_discount = this.discount_range[1] == 0 ? localStorage.getItem("filtered_data")!=null ? JSON.parse(localStorage.getItem("filtered_data")).max_discount : 0 : this.discount_range[1];
      this.custshopsearch.brands =  this.brand_selection.length != 0 ? this.brand_selection.join(","):localStorage.getItem("filtered_data")!=null ? JSON.parse(localStorage.getItem("filtered_data")).brand.join(',') : '';
      this.custshopsearch.categories =  this.category_selection.length != 0 ? this.category_selection.join(","):localStorage.getItem("filtered_data")!=null ? JSON.parse(localStorage.getItem("filtered_data")).category.join(',') : '';
      this.custshopsearch.out_of_stock = (this.out_of_stock == true) ? 0 : "";
      this.custshopsearch.prescription = this.prescription.length != 0 ? this.prescription.join(","):localStorage.getItem("filtered_data")!=null ? JSON.parse(localStorage.getItem("filtered_data")).prescription.join(',') : '';
      this.custshopsearch.page = this.page_number;
      this.loading = true;
      this.$store
        .dispatch("getshopsearchitemdetail", this.custshopsearch)
        .then(response => {
          setTimeout(() => (this.loading = false), 4000);
          var result = JSON.parse(JSON.stringify(response));
          if(result.status == 1) {
            this.record_count = result.count;
            result.data.forEach(e => {
                this.category_items.push(e);
            });

            if(this.category_items.length > 0) {
              this.getPreSelectedItem();
              this.category_items.forEach(e => {
                if(e.item_variants.length > 0)
                {
                 var obj = {};
                 obj['item_variants_id'] = e.item_variants[0].item_variants_id;
                 obj['quantity'] = (e.item_variants[0].quantity != null) ? e.item_variants[0].quantity : 1;
                 obj['is_added'] = 0;
                 this.item_variants.push(obj);
                }
              });
            }
            this.setFilterData(result);
          }
          this.loading = false;
        });
        if(window.$('.shop-prifile-section').length > 0)
        {
        var sticky_banner = window.$('.shop-prifile-section').height();
        window.$("html, body").animate({ scrollTop: (sticky_banner - 220) }, "slow");
        }
    },
    setFilterData(result) {
      if(result.filter_data != null) {
        this.filter_data = result.filter_data;

        if(this.filter_data.post_data_filter.min_price == 0 && this.filter_data.post_data_filter.max_price == 0 ){
          this.price_range = [this.filter_data.min_price, this.filter_data.max_price];
          this.filter_data_min_price = this.filter_data.min_price;
          this.filter_data_max_price = this.filter_data.max_price;
          this.currency_price_range = {};
          this.currency_price_range[this.filter_data.min_price] = {
              label: this.currency_symbol + this.filter_data.min_price,
          }
          this.currency_price_range[this.filter_data.max_price] = {
              label: this.currency_symbol + this.filter_data.max_price,
          }
        } else {
          this.price_range = [this.price_range>[0,0] && this.brand_selection!=null ? this.filter_data.post_data_filter.min_price : this.filter_data.min_price, this.price_range>[0,0] && this.brand_selection!=null ? this.filter_data.post_data_filter.max_price : this.filter_data.max_price];
          this.post_data_filter_min_price = this.filter_data.post_data_filter.min_price;
          this.post_data_filter_max_price = this.filter_data.post_data_filter.max_price;
          this.filter_data_min_price = this.filter_data.min_price;
          this.filter_data_max_price = this.filter_data.max_price;
          
          this.currency_price_range = {};
          this.currency_price_range[this.filter_data.post_data_filter.min_price] = {
              label: this.currency_symbol + this.filter_data.post_data_filter.min_price,
          }
          this.currency_price_range[this.filter_data.post_data_filter.max_price] = {
              label: this.currency_symbol + this.filter_data.post_data_filter.max_price,
          }
          
        }

        if(this.filter_data.post_data_filter.max_price > this.filter_data.max_price){
          this.price_range = [this.filter_data.min_price, this.filter_data.max_price];
          this.filter_data_min_price = this.filter_data.min_price;
          this.filter_data_max_price = this.filter_data.max_price;
          this.currency_price_range = {};
          this.currency_price_range[this.filter_data.min_price] = {
              label: this.currency_symbol + this.filter_data.min_price,
          }
          this.currency_price_range[this.filter_data.max_price] = {
              label: this.currency_symbol + this.filter_data.max_price,
          }

        }

        if(this.filter_data.post_data_filter.min_discount == 0 && this.filter_data.post_data_filter.max_discount == 0 ){
          this.discount_range = [this.filter_data.min_discount, this.filter_data.max_discount];
          this.filter_data_min_discount = this.filter_data.min_discount;
          this.filter_data_max_discount = this.filter_data.max_discount;
          this.currency_discount_range = {};
          this.currency_discount_range[this.filter_data.min_discount] = {
              label: this.filter_data.min_discount + '%',
          };
          this.currency_discount_range[this.filter_data.max_discount] = {
              label: this.filter_data.max_discount + '%',
          };
        } else {
          
          this.discount_range = [this.filter_data.post_data_filter.min_discount, this.filter_data.post_data_filter.max_discount];
          
          
          this.filter_data_min_discount = this.filter_data.min_discount;
          this.filter_data_max_discount = this.filter_data.max_discount;          

          this.currency_discount_range = {};
          this.currency_discount_range[this.filter_data.post_data_filter.min_discount] = {
              label: this.filter_data.post_data_filter.min_discount + '%',
          }
          this.currency_discount_range[this.filter_data.post_data_filter.max_discount] = {
              label: this.filter_data.post_data_filter.max_discount + '%',
          }
        }
        if(this.filter_data.post_data_filter.max_discount > this.filter_data.max_discount){
          this.discount_range = [this.filter_data.min_discount, this.filter_data.max_discount];
          this.filter_data_min_discount = this.filter_data.min_discount;
          this.filter_data_max_discount = this.filter_data.max_discount;
          this.currency_discount_range = {};
          this.currency_discount_range[this.filter_data.min_discount] = {
              label: this.filter_data.min_discount + '%',
          }
          this.currency_discount_range[this.filter_data.max_discount] = {
              label: this.filter_data.max_discount + '%',
          }

        }

        this.brand_items = this.filter_data.brands;
        this.filteredbrand_items = this.filter_data.brands;
        this.category_items_filter = this.filter_data.categories
        this.filteredcategory_items = this.filter_data.categories
      }
    },
    clearSearchItem(){
      localStorage.removeItem("user_filter_data");
      this.show_clear_search = false;
      this.searchproduct = '';
    },
    postSearchItem() {
      if(this.searchproduct != ''){
        this.searchedproduct = this.searchproduct;
        this.categroy_section = true;
        this.subcategory = false;
        this.offer_section = false;
        this.page_number=1;
        this.issearch = true;
        this.show_clear_search=true;

        //clear filter data before new search
        this.food_type = [];
        this.prescription = [];
        this.out_of_stock = false;
        this.price_range = [0,0];
        this.discount_range = [0,0];
        this.brand_selection = [];
        this.category_selection = [];
        this.brandsearch = "";
        this.categorysearch="";

        if(localStorage.getItem("user_filter_data") != null)
        {
          this.user_filter_data = JSON.parse(localStorage.getItem("user_filter_data"));
          this.user_filter_data.search_query=this.searchproduct;
          this.user_filter_data.category_id =0;
          this.user_filter_data.category_name ="";
          localStorage.setItem("user_filter_data",JSON.stringify(this.user_filter_data));
        }

        if(localStorage.getItem("filtered_data")!=null){
          this.filtered_data = JSON.parse(localStorage.getItem("filtered_data"));
          this.filtered_data.food_type = this.food_type;
          this.filtered_data.brand = this.brand_selection;
          this.filtered_data.availability = this.out_of_stock;
          this.filtered_data.prescription = this.prescription;
          this.filtered_data.min_price = this.price_range[0];
          this.filtered_data.max_price = this.price_range[1];
          this.filtered_data.price = [this.filtered_data.min_price, this.filtered_data.max_price];
          this.filtered_data.min_discount = this.discount_range[0];
          this.filtered_data.max_discount = this.discount_range[1];
          this.filtered_data.discount = [this.filtered_data.min_discount, this.filtered_data.max_discount];
          this.filtered_data.category = this.category_selection;
          localStorage.setItem("filtered_data",JSON.stringify(this.filtered_data));
        }

        this.searchShopItem();
        if(window.$('.categories').find('.not-collapsed').length > 0)
        {
          window.$('.categories').find('.not-collapsed').click()
        }
      }
    },
    showCatagoryToggle() {
        window.$('#CatagoryRow').addClass('active');
        window.$('#CategoriesMain').show();
        window.$('#ShopFilterMain').hide();
        window.$('.filter-over-layer').addClass('active');
        window.$('body').addClass('overflow-hidden');
        window.$("#Filtercollapse").hide();
        window.$("#Categoriescollapse").show();
    },
    hideCatagoryToggle() {
        window.$('#CatagoryRow').removeClass('active');
        window.$('#CategoriesMain').removeClass('show');
        window.$('#ShopFilterMain').removeClass('show');
        window.$('.filter-over-layer').removeClass('active');
        window.$('body').removeClass('overflow-hidden');
    },
    showFilterToggle() {
        window.$('#CatagoryRow').addClass('active');
        window.$('#CategoriesMain').hide();
        window.$('#ShopFilterMain').show();
        window.$('.filter-over-layer').addClass('active');
        window.$('body').addClass('overflow-hidden');
        window.$("#Filtercollapse").show();
        window.$("#Categoriescollapse").hide();
    },
    hideFilterToggle() {
        window.$('#CatagoryRow').removeClass('active');
        window.$('#CategoriesMain').removeClass('show');
        window.$('#ShopFilterMain').removeClass('show');
        window.$('.filter-over-layer').removeClass('active');
        window.$('body').removeClass('overflow-hidden');
    },
    shareStore(){
      window.$('#shareStoreModal').modal('show');
    },
    addFavoriteStore(storeid, favorite) {
      this.favrtebody.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.favrtebody.store_id = storeid;
      this.favrtebody.is_favourite = favorite;
      this.$store
        .dispatch("getstorefavorite", this.favrtebody)
        .then(response => {
          if (response.status == 1) {
            if (favorite == 1) {
              window.$("#dislike").show();
              window.$("#like").hide();
              this.getStoreDetails();
              this.getshopdetaildata.data.store.is_favourite = 0;
              this.$toast.success("Store added to favourite successfully");
            } else {
              window.$("#like").show();
              window.$("#dislike").hide();
              this.getStoreDetails();
              this.getshopdetaildata.data.store.is_favourite = 1;
              this.$toast.success("Store removed from favourite successfully");
            }
          }
        });
    },
    getFilteredStoreAddress(store){
      if(store){
        var tmp_store_address = [];
        tmp_store_address.push(store.store_address, store.store_address2, store.area_name, store.city_name, store.zipcode);
        var filtered_store_address = tmp_store_address.filter(function (el) { return el; });
        
        if(filtered_store_address.length > 0){
          return filtered_store_address.join(', ');
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    getFilteredCuisines(cuisines){
      if(cuisines){
        var tmp_cuisines = [];
        cuisines.forEach(value => {
          tmp_cuisines.push(value.cuisine_name);
        });
        var filtered_cuisines = tmp_cuisines.filter(function (el) { return el; });
        
        if(filtered_cuisines.length > 0){
          return filtered_cuisines.join(', ');
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    ToggleView(viewType){
       if(viewType == 'list'){
         this.showListView = true;
       }
       else if(viewType == 'grid')
       {
         this.showListView = false;
       }
       this.closeFilter();
    },
    nextStoreWorkingDay(business_hours) {
       if(business_hours != null && business_hours.length > 0) {
         //this.timezone = (localStorage.getItem("timezone")!=null) ? localStorage.getItem("timezone") : 'Asia/Kolkata';
          const currentdaymoment = moment.tz(this.getshopdetaildata.data.store.timezone);
          var new_current_day;
          var current_day = currentdaymoment.day() == 0 ? 6 : currentdaymoment.day()-1;
          switch (current_day) {
            case 0:
            new_current_day = 6;
            break;
            case 1:
            new_current_day = 0;
            break;
            case 2:
            new_current_day = 1;
            break;
            case 3:
            new_current_day = 2;
            break;
            case 4:
            new_current_day = 3;
            break;
            case 5:
            new_current_day = 4;
            break;
            case 6:
            new_current_day = 5;
          }
         //var timezone = (localStorage.getItem("timezone")!=null) ? localStorage.getItem("timezone") : 'Asia/Kolkata';
          const now = moment.tz(this.getshopdetaildata.data.store.timezone);
          if(parseInt(business_hours[0].days) != parseInt(new_current_day) && business_hours.length > 1)
          {
          var daysToAdd = parseInt(current_day) - parseInt(business_hours[0].days);
          if(daysToAdd < 0)
          {
            daysToAdd = parseInt(business_hours[0].days) - parseInt(current_day); 
          }
          return now.add(daysToAdd,'days').format("DD MMM");
          }
          else if(parseInt(business_hours[0].days) != parseInt(new_current_day) && business_hours.length > 0)
          {
          return now.add(parseInt(business_hours[0].days),'days').format("DD MMM");
          }
          else{
            return "";
          }
       }
    },
    isStoreOpen(business_hours){
      if (business_hours != null && business_hours.length > 0) {
          //var timezone = (localStorage.getItem("timezone")!=null) ? localStorage.getItem("timezone") : 'Asia/Kolkata';
          var openTime = business_hours[0].from_time;
          var closeTime = business_hours[0].to_time;
          const now = moment.tz(this.getshopdetaildata.data.store.timezone);
 
          const date = now.format("YYYY-MM-DD");
          var current_day = now.day();
          var new_current_day;
          switch (current_day) {
            case 0:
            new_current_day = 6;
            break;
            case 1:
            new_current_day = 0;
            break;
            case 2:
            new_current_day = 1;
            break;
            case 3:
            new_current_day = 2;
            break;
            case 4:
            new_current_day = 3;
            break;
            case 5:
            new_current_day = 4;
            break;
            case 6:
            new_current_day = 5;
          }
          if(new_current_day != business_hours[0].days){
            return "Closed Now";
          }

          let check;
          const storeOpenTime = moment.tz(date + ' ' + openTime, "YYYY-MM-DD h:mmA", this.getshopdetaildata.data.store.timezone);
          const storeCloseTime = moment.tz(date + ' ' + closeTime, "YYYY-MM-DD h:mmA", this.getshopdetaildata.data.store.timezone);

          if (storeCloseTime.isBefore(storeOpenTime)) {
            check = now.isAfter(storeOpenTime) || now.isBefore(storeCloseTime);
          } else {
            check = now.isBetween(storeOpenTime, storeCloseTime, null, '[)');
          }
          return check ? "Open Now" : "Closed Now";
      } else {
        return "Closed Now";
      }
    },
    getStoreByCategory(category){
      if(localStorage.getItem("is_business_model") == 3){
        return true;
      } else {
        this.category.shop_category_id = category.category_id;
        this.category.shop_category_name = category.category_name;
        this.category.type = "category";
        localStorage.setItem("selected-category", JSON.stringify(this.category));
        this.$router.push({ path: `/category?categoryid=${this.category.shop_category_id.toString()}` });
      }
    },
    selectCategory(cat_id, cat_name, cevent = false) {
      this.selected_category_id = '';
      this.selected_subcategory_id = '';
      this.categroy_section = true;
      this.offer_section = false;
      this.searchproduct = "";
      this.show_clear_search=false;
      this.issearch = false;
      this.selected_category_id = cat_id;
      this.selected_category_name = cat_name;
      this.page_number=1;
      this.brand_selection = [];
      this.category_selection = [];
      this.price_range = [0,0];
      this.discount_range = [0,0];
      if(localStorage.getItem("user_filter_data") != null)
      {
        this.user_filter_data = JSON.parse(localStorage.getItem("user_filter_data"));
        this.user_filter_data.selected_category_id=this.selected_category_id;
        this.user_filter_data.selected_category_name=this.selected_category_name;
        this.user_filter_data.search_query="";
        localStorage.setItem("user_filter_data",JSON.stringify(this.user_filter_data));
      }

      if(localStorage.getItem("filtered_data")!=null){
        this.filtered_data = JSON.parse(localStorage.getItem("filtered_data"));
        this.filtered_data.brand = this.brand_selection;
        this.filtered_data.min_price = this.price_range[0];
        this.filtered_data.max_price = this.price_range[1];
        this.filtered_data.price = [this.filtered_data.min_price, this.filtered_data.max_price];
        this.filtered_data.min_discount = this.discount_range[0];
        this.filtered_data.max_discount = this.discount_range[1];
        this.filtered_data.discount = [this.filtered_data.min_discount, this.filtered_data.max_discount];
        this.filtered_data.category = this.category_selection;
        localStorage.setItem("filtered_data",JSON.stringify(this.filtered_data));
      }

      this.getShopItemDetails();
      this.hideCatagoryToggle();
      this.hideFilterToggle();
      if(cevent == true && window.$('.shop-prifile-section').length > 0){
        var sticky_banner = window.$('.shop-prifile-section').height();
        window.$("html, body").animate({ scrollTop: (sticky_banner - 220) }, "slow");
      }
    },
    selectSubCategory(cat_id, sub_cat_id,cat_name, subcat_name, cevent = false) {
      this.selected_category_id = '';
      this.selected_subcategory_id = '';
      this.categroy_section = true;
      this.offer_section = false;
      this.searchproduct = "";
      this.show_clear_search=false;
      this.issearch = false;
      this.selected_category_id = cat_id;
      this.selected_subcategory_id = sub_cat_id;
      this.selected_category_name = cat_name;
      this.selected_subcategory_name = subcat_name;
      this.page_number=1;
      this.brand_selection = [];
      this.category_selection = [];
      this.price_range = [0,0];
      this.discount_range = [0,0];

      if(localStorage.getItem("filtered_data")!=null){
        this.filtered_data = JSON.parse(localStorage.getItem("filtered_data"));
        this.filtered_data.brand = this.brand_selection;
        this.filtered_data.min_price = this.price_range[0];
        this.filtered_data.max_price = this.price_range[1];
        this.filtered_data.price = [this.filtered_data.min_price, this.filtered_data.max_price];
        this.filtered_data.min_discount = this.discount_range[0];
        this.filtered_data.max_discount = this.discount_range[1];
        this.filtered_data.discount = [this.filtered_data.min_discount, this.filtered_data.max_discount];
        this.filtered_data.category = this.category_selection;
        localStorage.setItem("filtered_data",JSON.stringify(this.filtered_data));
      }
      
      this.getShopItemDetails();
      this.hideCatagoryToggle();
      this.hideFilterToggle();
      if(cevent == true && window.$('.shop-prifile-section').length > 0){
        var sticky_banner = window.$('.shop-prifile-section').height();
        window.$("html, body").animate({ scrollTop: (sticky_banner - 220) }, "slow");
      }
    },
    selectOffer() {
      this.categroy_section = false;
      this.subcategory = false;
      this.offer_section = true;
      this.getShopOffers('category-click');
      this.hideCatagoryToggle();
      this.hideFilterToggle();
      if(window.$('.shop-prifile-section').length > 0)
      {
      var sticky_banner = window.$('.shop-prifile-section').height();
      window.$("html, body").animate({ scrollTop: (sticky_banner - 220) }, "slow");
      }
    },
    viewOfferDetail(offer_list) {
      bus.$emit("offerdetail", offer_list);
      window.$("#Detailpopup").modal("show");
    },
    onChangePage(page_of_items) {
      this.page_of_items = page_of_items;
    },
    nextPage() {
      this.page_number++;
      if(this.is_search)
      {
        this.searchShopItem();
      }
      else
      {
        this.getShopItemDetails();
      }
      
      if(window.$('.shop-prifile-section').length > 0){
        var sticky_banner = window.$('.shop-prifile-section').height();
        window.$("html, body").animate({ scrollTop: (sticky_banner - 220) }, "slow");
      }
    },
    prevPage() {
      this.page_number--;
      if(this.is_search)
      {
        this.searchShopItem();
      }
      else
      {
        this.getShopItemDetails();
      }
      if(window.$('.shop-prifile-section').length > 0){
        var sticky_banner = window.$('.shop-prifile-section').height();
        window.$("html, body").animate({ scrollTop: (sticky_banner - 220) }, "slow");
      }
    },
    nextPageSubcat() {
      this.sub_page_number++;
    },
    prevPageSubcat() {
      this.sub_page_number--;
    },
    nextPageOffer() {
      this.offer_page_number++;
      if(window.$('.shop-prifile-section').length > 0){
        var sticky_banner = window.$('.shop-prifile-section').height();
        window.$("html, body").animate({ scrollTop: (sticky_banner - 220) }, "slow");
      }
    },
    prevPageOffer() {
      this.offer_page_number--;
      if(window.$('.shop-prifile-section').length > 0){
        var sticky_banner = window.$('.shop-prifile-section').height();
        window.$("html, body").animate({ scrollTop: (sticky_banner - 220) }, "slow");
      }
    },
    getPreSelectedItem(){
      if(localStorage.getItem("user") == null && localStorage.getItem("user_cart") != null) {
        var user_cart = JSON.parse(localStorage.getItem("user_cart")) || [];

        bus.$emit("getcartlength", user_cart.length);

        if(this.category_items.length > 0 && user_cart.length > 0) {
          
          user_cart.forEach(e => {

            let item_index = this.category_items.findIndex(
                o => o.item_id == e.item_id
            );

            if (item_index > -1){

              let item_variant_index = this.category_items[item_index].item_variants.findIndex(
                  o => o.item_variants_id == e.item_variants_id
              );

              if (item_variant_index > -1){
                //this.category_items[item_index].item_variants[item_variant_index].is_added = 1;
                this.category_items[item_index].item_variants[item_variant_index].quantity = e.quantity;
              }
            }

          });
        }
      }
    },
    isItemAdded(item, index){
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.itemVariantData[index].item_variants_id
        ) != undefined
      ) {
        if (
          item.item_variants.find(
            o => o.item_variants_id == this.itemVariantData[index].item_variants_id
          ).is_added == 1 
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getItemQuantity(item, index){
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.itemVariantData[index].item_variants_id
        ) != undefined
      ) {
        if(item.item_variants.find(
          o => o.item_variants_id == this.itemVariantData[index].item_variants_id
        ).quantity != null){
          return item.item_variants.find(
            o => o.item_variants_id == this.itemVariantData[index].item_variants_id
          ).quantity;
        } else {
          return 1;  
        }
      } else {
        return 1;
      }
    },
    getItemVarintId(item, index) {
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.itemVariantData[index].item_variants_id
        ) != undefined
      ) {
        bus.$emit(
          "itemvariantid",
          item.item_variants.find(
            o => o.item_variants_id == this.itemVariantData[index].item_variants_id
          ).item_variants_id
        );
        return item.item_variants.find(
          o => o.item_variants_id == this.itemVariantData[index].item_variants_id
        ).item_variants_id;
      } else {
        return false;
      }
    },
    getItemPrice: function(item, index) {
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.itemVariantData[index].item_variants_id
        ) != undefined
      ) {
          var item_packing = item.item_variants.find(
            o => o.item_variants_id == this.itemVariantData[index].item_variants_id
          ).packaging;
          var item_price = item.item_variants.find(
            o => o.item_variants_id == this.itemVariantData[index].item_variants_id
          ).item_price;
          var tax_item_price = item.item_variants.find(
            o => o.item_variants_id == this.itemVariantData[index].item_variants_id
          ).tax_item_price;
          var total_sum = 0;
        if (
          item.item_variants.find(
            o => o.item_variants_id == this.itemVariantData[index].item_variants_id
          ).discounted_price ==
          item.item_variants.find(
            o => o.item_variants_id == this.itemVariantData[index].item_variants_id
          ).item_price
        ) {
          return '0';
        } else {
          if(this.show_price_inclusive_tax == 1 && this.show_price_inclusive_packaging == 1){
            total_sum = parseFloat(tax_item_price) + (item_packing!=null ? parseFloat(item_packing) : 0);
            return parseFloat(total_sum).toFixed(2);
          } else if(this.show_price_inclusive_packaging == 1){
            total_sum = parseFloat(item_price) + (item_packing!=null ? parseFloat(item_packing) : 0);
            return parseFloat(total_sum).toFixed(2);
          } else if(this.show_price_inclusive_tax == 1){
            total_sum = parseFloat(tax_item_price);
            return parseFloat(total_sum).toFixed(2);
          } else {
            return item_price;
          }
        }
      }
    },
    getItemDiscountedPrice: function(item, index) {
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.itemVariantData[index].item_variants_id
        ) != undefined
      ) {
          var item_packing = item.item_variants.find(
            o => o.item_variants_id == this.itemVariantData[index].item_variants_id
          ).packaging;
          var discounted_item_price = item.item_variants.find(
            o => o.item_variants_id == this.itemVariantData[index].item_variants_id
          ).discounted_price;
          var item_price = item.item_variants.find(
            o => o.item_variants_id == this.itemVariantData[index].item_variants_id
          ).item_price;
          var tax_item_price = item.item_variants.find(
            o => o.item_variants_id == this.itemVariantData[index].item_variants_id
          ).tax_item_price;
          var tax_item_discounted_price = item.item_variants.find(
            o => o.item_variants_id == this.itemVariantData[index].item_variants_id
          ).tax_item_price;
          var total_sum = 0;
        if (
          parseFloat(item.item_variants.find(
            o => o.item_variants_id == this.itemVariantData[index].item_variants_id
          ).discounted_price) <
          parseFloat(item.item_variants.find(
            o => o.item_variants_id == this.itemVariantData[index].item_variants_id
          ).item_price)
        ) {  
          if(this.show_price_inclusive_tax == 1 && this.show_price_inclusive_packaging == 1){
            total_sum = parseFloat(tax_item_discounted_price) + (item_packing!=null ? parseFloat(item_packing) : 0);
            return parseFloat(total_sum).toFixed(2);
          } else if(this.show_price_inclusive_packaging == 1){
            total_sum = parseFloat(discounted_item_price) + (item_packing!=null ? parseFloat(item_packing) : 0);
            return parseFloat(total_sum).toFixed(2);
          } else if(this.show_price_inclusive_tax == 1){
            total_sum = parseFloat(tax_item_discounted_price);
            return parseFloat(total_sum).toFixed(2);
          } else {
            return discounted_item_price;
          }
        } else {
          if(this.show_price_inclusive_tax == 1 && this.show_price_inclusive_packaging == 1){
            total_sum = parseFloat(item_price) + (item_packing!=null ? parseFloat(item_packing) : 0);
            return parseFloat(tax_item_price).toFixed(2);
          } else if(this.show_price_inclusive_packaging == 1){
            total_sum = parseFloat(item_price) + (item_packing!=null ? parseFloat(item_packing) : 0);
            return parseFloat(total_sum).toFixed(2);
          } else if(this.show_price_inclusive_tax == 1){
            total_sum = parseFloat(tax_item_price);
            return parseFloat(total_sum).toFixed(2);
          } else {
            return item_price;
          }
        }
      }
    },
    addQuantity(item, index) {
      var qty = parseInt(window.$("#quantityid" + item.item_id).val());
      if(item.item_variants[0].max_qty <= qty && qty != 0 && item.item_variants[0].max_qty != 0){
        this.$toast.error("The maximum quantity for item is " + item.item_variants[0].max_qty);
        return false;
      }
      qty = qty + 1;
      window.$("#quantityid" + item.item_id).val(parseInt(qty));
      this.item_qty = parseInt(qty);
      this.addToWishlist(item, index);
      return false;
    },
    removeQuantity(item, index) {
      var qty = parseInt(window.$("#quantityid" + item.item_id).val());
      if(item.item_variants[0].min_qty > qty && qty != 0 & item.item_variants[0].min_qty != 0){
        this.$toast.error("The minimum quantity for item is " + item.item_variants[0].min_qty);
        return false;
      }
      if(item.item_variants[0] != undefined)
      {
        if (item.item_variants[0].min_qty >= qty)
          qty=0;
        else
          qty = qty -1;        
      }
      else{
          qty = qty -1;
      }
      if(qty != 0 && (this.checkItemAddonChoice(item,index) != null) || item.is_combo == 1){
        window.$('#CustomizeAlertModalshop').modal("show");
        return false;
      } 
      if(qty >= 0){
      window.$("#quantityid" + item.item_id).val(parseInt(qty));
      this.item_qty = parseInt(qty);
      this.addToWishlist(item, index);
      }
      return false;
    },
    isStoreExistsInLocalCart(user_cart, cart_details){
      if(user_cart.length > 0){
        const i = user_cart.findIndex(o => o.store_id === cart_details.store_id);
        if (i > -1) return true;
        else return false;
      } else {
        return true;
      }
    },
    isItemExistsInLocalCart(user_cart, cart_details){
      const i = user_cart.findIndex(o => o.item_id === cart_details.item_id && o.item_variants_id === cart_details.item_variants_id);
      if (i > -1) {
        user_cart[i] = cart_details;
        if(cart_details.quantity == 0){
          user_cart.splice(i, 1);
        }
      } else { 
        user_cart.push(cart_details);
      }
      return user_cart;
    },
    removeLocalCart() {
      var user_cart = JSON.parse(localStorage.getItem("user_cart")) || [];

      if(this.category_items.length > 0 && user_cart.length > 0) {

        user_cart.forEach(e => {

          let item_index = this.category_items.findIndex(
              o => o.item_id == e.item_id
          );

          if (item_index > -1){

            let item_variant_index = this.category_items[item_index].item_variants.findIndex(
                o => o.item_variants_id == e.item_variants_id
            );

            if (item_variant_index > -1){
              this.category_items[item_index].item_variants[item_variant_index].quantity = 1;
            }
          }

        });
      }

      this.local_cart = [];
      localStorage.removeItem("user_cart");
      bus.$emit("getcartlength", this.local_cart.length);
    },
    totalLocalCartQuantity(user_cart) {
      var total_qty = 0;
      user_cart.forEach(e => {
        total_qty+= parseFloat(e.quantity);
      });
      return Number(total_qty).toString();
    },
    getItemMinQty(item, index){
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.itemVariantData[index].item_variants_id
        ) != undefined
      ) {
          return item.item_variants.find(
            o => o.item_variants_id == this.itemVariantData[index].item_variants_id
          ).min_qty;
      } else {
        return null;
      }
    },
    getItemMaxQty(item, index){
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.itemVariantData[index].item_variants_id
        ) != undefined
      ) {
          return item.item_variants.find(
            o => o.item_variants_id == this.itemVariantData[index].item_variants_id
          ).max_qty;
      } else {
        return null;
      }
    },
    addMinQty(item, index) {
      var min_quantity = this.getItemMinQty(item,index);
      window.$("#quantityid" + item.item_id).val(parseInt(min_quantity));
      this.addToCart(item, index);
      window.$('#QuantityAlertModal'+index).modal("hide");
    },
    removeMinQty(item, index) {
      window.$("#quantityid" + item.item_id).val(parseInt(0));
      this.addToCart(item, index);
      window.$('#QuantityAlertModal'+index).modal("hide");
    },
    addToCart(item, index) {
      if(localStorage.getItem("user") == null){
        this.openLoginfrm(item.item_id);
        return false;
      }
      this.add_to_cart.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.add_to_cart.store_id = parseInt(this.storeid);
      this.add_to_cart.item_id = item.item_id;
      //this.add_to_cart.add_new = 0;
      this.add_to_cart.item_variants_id = parseInt(window.$("#itemvarintid" + item.item_id).val());
      this.add_to_cart.quantity = parseInt((this.item_qty == 0) ? window.$("#quantityid" + item.item_id).val() : this.item_qty);
      this.add_to_cart.cart_type = 1;
      this.remove_min_qty = false;

      if(item.is_combo == 1 && this.combo_cart == false && this.add_to_cart.quantity != 0 && localStorage.getItem("user") != null){
        var is_repeat = false;
        if(this.isItemAdded(item, index) == true && this.add_to_cart.add_new == 0){
          this.repeat_combo = null;
          is_repeat = true;
        } else {
          is_repeat = false;
        }
        this.getItemCombo(item, index, is_repeat);
        return false;
      } else {
        this.combo_cart = false;
      }

      if(this.getItemMinQty(item,index) != null){
        if(this.getItemMinQty(item,index) != 0 && this.getItemMinQty(item,index) > this.add_to_cart.quantity && this.add_to_cart.quantity != 0 && this.isItemAdded(item,index) == false){
          this.is_min_qty = true;
          this.qty_alert_message = "The minimum quantity for item is " + this.getItemMinQty(item,index) + ", Do you want to proceed?"
          window.$('#QuantityAlertModal'+index).modal("show");
          return false;
        } else if(this.getItemMinQty(item,index) != 0 && this.getItemMinQty(item,index) > this.add_to_cart.quantity && this.add_to_cart.quantity != 0 && this.isItemAdded(item,index) == true) {
          this.is_min_qty = true;
          this.remove_min_qty = true;
          this.qty_alert_message = "The minimum quantity for item is " + this.getItemMinQty(item,index) + ", Do you wish to remove?"
          window.$('#QuantityAlertModal'+index).modal("show");
          return false;
        }
      }

      if(this.getItemMaxQty(item,index) != null){
        if(this.getItemMaxQty(item,index) != 0 && this.getItemMaxQty(item,index) < this.add_to_cart.quantity){
          window.$("#quantityid" + item.item_id).val(parseInt(this.getItemMaxQty(item,index)));
          this.is_min_qty = false;
          this.qty_alert_message = "The maximum quantity for item is " + this.getItemMaxQty(item,index) + "."
          window.$('#QuantityAlertModal'+index).modal("show");
          return false;
        }
      }

      if(this.checkItemAddonChoice(item,index) != null && this.addon_choice_cart == false && this.add_to_cart.quantity != 0  && localStorage.getItem("user") != null){
        if(this.isItemAdded(item, index) == true && this.add_to_cart.add_new == 0){
          this.repeat_addon_choice = null;
          this.getRepeatAddonChoices(index, this.add_to_cart.store_id, this.add_to_cart.item_variants_id);
        } else {
          this.getItemAddonChoices(item, index, item.item_name);
        }
        return false;
      } else {
        this.addon_choice_cart = false;
      }
    },
    openLoginfrm(productid) {
      localStorage.setItem("SelectedProductBeforeLogin",productid);      
      localStorage.setItem("LastUrlBeforeLogin",window.location.href);
      window.$("#login-form").modal("show");
      window.$("#VerificationFields").removeClass("show");
      window.$("#SignupFields").removeClass("show");
      window.$("#ChangePassword").removeClass("show");
      window.$("#loginPopupFields").addClass("show");
    },
    productNotify(productid) {
      if(localStorage.getItem("user") == null){
        this.openLoginfrm(productid);
        return false;
      }
      this.product_notify.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.product_notify.item_id = productid;
      this.$store
        .dispatch("getcustnotifyme", this.product_notify)
        .then(response => {
          if (response.status == 1) {
            window.$("#btnnotifyme" + this.product_notify.item_id).removeClass("btn btn-primary");
            window.$("#btnnotifyme" + this.product_notify.item_id).addClass("btn notifymegrayitem");
            this.itemPaginatedData.find(
              o => o.item_id == this.product_notify.item_id
            ).notify_me = 1;
            this.$toast.success("You will be notified once the item is in stock");
          } else {
            this.$toast.error(response.message);
          }
        });
    },
    clipboardSuccessHandler () {
      this.$toast.success("Code copied to clipboard");
    },
    clipboardSocialSuccessHandler () {
      this.$toast.success("URL copied to clipboard");
      window.$('#shareStoreModal').modal('hide');
    },
    checkItemAddonChoice(item, index){
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.itemVariantData[index].item_variants_id
        ) != undefined
      ) {
          return item.item_variants.find(
            o => o.item_variants_id == this.itemVariantData[index].item_variants_id
          ).is_choice_addon;
      } else {
        return null;
      }
    },
    getChoiceAddon(item, value) {
      return (item.is_choice_addon == value) ? item.is_choice_addon : "null";
    },
    getItemAddonChoices(item, index, item_name) {
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.itemVariantData[index].item_variants_id
        ) != undefined
      ) {
          var selected_item_variant = item.item_variants.find(
            o => o.item_variants_id == this.itemVariantData[index].item_variants_id
          );
          bus.$emit("ac_item", item);
          bus.$emit("ac_item_index", index);
          bus.$emit("ac_item_variant", selected_item_variant);
          bus.$emit("ac_item_name", item_name);
          window.$("#AvailableOptionshop").modal("show");
      } else {
        return false;
      }
    },
    isItemExistsUsedAddonChoices(local_used_addon_choice, used_addon_choice){
      const i = local_used_addon_choice.findIndex(o => o.item_id == used_addon_choice.item_id && o.item_variants_id == used_addon_choice.item_variants_id);
      if (i > -1) {
        local_used_addon_choice[i] = used_addon_choice;
      } else { 
        local_used_addon_choice.push(used_addon_choice);
      }
      return local_used_addon_choice;
    },
    addToCartAddonChoices(data){
      this.addon_choice_cart = true;
      this.add_to_cart.choices = JSON.stringify(data.choices);
      this.add_to_cart.addons = JSON.stringify(data.addons);

      // Used Addon Choice Local Storage
      this.used_addon_choice.item_id = data.item.item_id;
      this.used_addon_choice.item_name = data.item.item_name;
      this.used_addon_choice.brand_name = data.item.brand_name;
      this.used_addon_choice.item_variants_id = data.item_variant.item_variants_id;
      this.used_addon_choice.item_price = (parseFloat(data.item_variant.discounted_price) + parseFloat(data.addon_choice_price));
      this.used_addon_choice.choice_details = data.choices;
      this.used_addon_choice.addon_details = data.addons;

      var local_used_addon_choice = JSON.parse(localStorage.getItem("used_addon_choice")) || [];
      if(localStorage.getItem("used_addon_choice") != null) {
        var new_used_addon_choice = this.isItemExistsUsedAddonChoices(local_used_addon_choice, this.used_addon_choice);
        localStorage.setItem('used_addon_choice', JSON.stringify(new_used_addon_choice));
      } else {
        local_used_addon_choice.push(this.used_addon_choice);
        localStorage.setItem('used_addon_choice', JSON.stringify(local_used_addon_choice));
      }
      this.addToCart(data.item, data.index);
    },
    addToWishlistAddonChoices(data){
      this.addon_choice_cart = true;
      this.add_to_wishlist.choices = JSON.stringify(data.choices);
      this.add_to_wishlist.addons = JSON.stringify(data.addons);

      // Used Addon Choice Local Storage
      this.used_addon_choice.item_id = data.item.item_id;
      this.used_addon_choice.item_name = data.item.item_name;
      this.used_addon_choice.brand_name = data.item.brand_name;
      this.used_addon_choice.item_variants_id = data.item_variant.item_variants_id;
      this.used_addon_choice.item_price = (parseFloat(data.item_variant.discounted_price) + parseFloat(data.addon_choice_price));
      this.used_addon_choice.choice_details = data.choices;
      this.used_addon_choice.addon_details = data.addons;

      var local_used_addon_choice = JSON.parse(localStorage.getItem("used_addon_choice")) || [];
      if(localStorage.getItem("used_addon_choice") != null) {
        var new_used_addon_choice = this.isItemExistsUsedAddonChoices(local_used_addon_choice, this.used_addon_choice);
        localStorage.setItem('used_addon_choice', JSON.stringify(new_used_addon_choice));
      } else {
        local_used_addon_choice.push(this.used_addon_choice);
        localStorage.setItem('used_addon_choice', JSON.stringify(local_used_addon_choice));
      }
      this.addToWishlist(data.item, data.index);
    },
    repeatItemAddonChoices(item, index){
      this.addon_choice_cart = true;
      this.add_to_wishlist.add_new = 0;
      this.addToWishlist(item, index);
      window.$('#RepeatAlertModal'+index).modal("hide");
    },
    addNewItemAddonChoices(item, index){
      this.addon_choice_cart = false;
      this.add_to_wishlist.add_new = 1;
      this.addToWishlist(item, index);
      window.$('#RepeatAlertModal'+index).modal("hide");
    },
    getRepeatAddonChoices(index, store_id, item_variants_id){
      this.used_choices_addons.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.used_choices_addons.store_id = store_id;
      this.used_choices_addons.item_variants_id = item_variants_id;
      this.$store
        .dispatch("usedchoicesaddons", this.used_choices_addons)
        .then(response => {
          if (response.status == 1) {
            this.repeat_addon_choice = JSON.parse(JSON.stringify(response.data));
            window.$('#RepeatAlertModal'+index).modal("show");
          } else {
            this.repeat_addon_choice = null;
          }
        });
    },
    getItemCombo(item, index, is_repeat) {
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.itemVariantData[index].item_variants_id
        ) != undefined
      ) {
          var selected_item_variant = item.item_variants.find(
            o => o.item_variants_id == this.itemVariantData[index].item_variants_id
          );
          bus.$emit("comb_item", item);
          bus.$emit("comb_item_index", index);
          bus.$emit("comb_item_variant", selected_item_variant);
          bus.$emit("comb_item_name", item.item_name);
          
          this.checkComboItem(item, index, selected_item_variant, is_repeat);
      } else {
        return false;
      }
    },
    checkComboItem(item, index, selected_item_variant, is_repeat) {
       this.combo_item_data.item_id = item.item_id;
       this.combo_item_data.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
       this.$store
         .dispatch("getproductdetail", this.combo_item_data)
         .then(response => {
           this.combo_loading = false;
           var result = JSON.parse(JSON.stringify(response));
           if(result.status == 1 && result.data.combo_items != null){
             
             this.combo_item_details = result.data.combo_items;
             var vm = this;
             this.selectedComboItems = [];
             var has_addon_choices = false;
             this.combo_item_details.forEach(function(combo_items) {
              var filter_combo_items = combo_items.combo_variants.filter(sub => sub.addons != null || sub.choices != null);
              if(!has_addon_choices){
                has_addon_choices = (filter_combo_items.length > 0) ? true : false;
              }
              
              combo_items.combo_variants.forEach(function(citem) {
                var obj = {};
                obj['combo_item_id'] = combo_items.combo_item_id;
                obj['item_name'] = citem.item_name;
                obj['combo_item_detail_id'] = citem.combo_item_detail_id;
                obj['quantity'] = 1;
                vm.selectedComboItems.push(obj);
              });
             });
             result.data.combo_items.forEach(comboVariantObj => {
               if(comboVariantObj.combo_variants.length > 1)
               {
                 has_addon_choices = true;
               }
             })
             if(!has_addon_choices) {
               var selected_data = {};
               selected_data['item'] = item;
               selected_data['index'] = index;
               selected_data['item_variant'] = selected_item_variant;
               selected_data['combo_items'] = this.selectedComboItems;
               this.addToCartCombo(selected_data);
             } else {
                if(is_repeat){
                  this.getRepeatCombo(index, this.add_to_cart.store_id, this.add_to_cart.item_variants_id);
                } else {
                  window.$("#comboOffershop").modal("show");
                }
               
             }
           }
         });
    },
    isItemExistsUsedCombo(local_used_combo, used_combo){
      const i = local_used_combo.findIndex(o => o.item_id == used_combo.item_id && o.item_variants_id == used_combo.item_variants_id);
      if (i > -1) {
        local_used_combo[i] = used_combo;
      } else { 
        local_used_combo.push(used_combo);
      }
      return local_used_combo;
    },
    addToWishlistComobo(data){
      this.combo_cart = true;
      data.combo_items.map(item => {
        delete item.item_name;
        return item;
      });
      this.add_to_wishlist.combo_variants = JSON.stringify(data.combo_items);
      this.add_to_wishlist.combo_variants_item = data.combo_variants;
      // Used Addon Choice Local Storage
      this.used_combo.item_id = data.item.item_id;
      this.used_combo.item_name = data.item.item_name;
      this.used_combo.brand_name = data.item.brand_name;
      this.used_combo.item_variants_id = data.item_variant.item_variants_id;
      this.used_combo.item_price = (parseFloat(data.item_variant.discounted_price));
      this.used_combo.combo_items = data.combo_items;

      var local_used_combo = JSON.parse(localStorage.getItem("used_combo")) || [];
      if(localStorage.getItem("used_combo") != null) {
        var new_used_combo = this.isItemExistsUsedAddonChoices(local_used_combo, this.used_combo);
        localStorage.setItem('used_combo', JSON.stringify(new_used_combo));
      } else {
        local_used_combo.push(this.used_combo);
        localStorage.setItem('used_combo', JSON.stringify(local_used_combo));
      }
      this.addToWishlist(data.item, data.index);
    },
    repeatItemCombo(item, index){
      this.combo_cart = true;
      this.add_to_wishlist.add_new = 0;
      this.addToWishlist(item, index);
      window.$('#RepeatComboAlertModal'+index).modal("hide");
    },
    addNewItemCombo(item, index){
      this.combo_cart = false;
      this.add_to_wishlist.add_new = 1;
      this.addToWishlist(item, index);
      window.$('#RepeatComboAlertModal'+index).modal("hide");
    },
    getRepeatCombo(index, store_id, item_variants_id){
      this.used_combo_data.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.used_combo_data.store_id = store_id;
      this.used_combo_data.item_variants_id = item_variants_id;
      this.$store
        .dispatch("usedchoicesaddons", this.used_combo_data)
        .then(response => {
          if (response.status == 1) {
            this.repeat_combo = JSON.parse(JSON.stringify(response.data));
            window.$('#RepeatComboAlertModal'+index).modal("show");
          } else {
            this.repeat_combo = null;
          }
        });
    },
    closeAddNewCombo(data){
      if(data){
        this.add_to_cart.add_new = 0;
      }
    },
    filterShopItemForBrandAndCategory()
    {
      this.price_range = [0,0];
      this.discount_range = [0,0];

      var sticky_banner = window.$('.shop-prifile-section').height();
      window.$("html, body").animate({ scrollTop: (sticky_banner - 220) }, "slow");
      this.hideCatagoryToggle();
      this.hideFilterToggle();
      if(this.searchproduct != '') 
      {
        this.searchedproduct = this.searchproduct;
        this.categroy_section = true;
        this.subcategory = false;
        this.offer_section = false;
        this.page_number = 1;
        this.issearch = true;
        this.show_clear_search = true;
        if(localStorage.getItem("user_filter_data") != null) {
          this.user_filter_data = JSON.parse(localStorage.getItem("user_filter_data"));
          this.user_filter_data.search_query=this.searchproduct;
          this.user_filter_data.category_id =0;
          this.user_filter_data.category_name ="";
          localStorage.setItem("user_filter_data",JSON.stringify(this.user_filter_data));
        }
        if(localStorage.getItem("filtered_data")!=null){
          this.filtered_data = JSON.parse(localStorage.getItem("filtered_data"));
          this.filtered_data.food_type = this.food_type;
          this.filtered_data.brand = this.brand_selection;
          this.filtered_data.availability = this.out_of_stock;
          this.filtered_data.prescription = this.prescription;
          this.filtered_data.min_price = this.price_range[0];
          this.filtered_data.max_price = this.price_range[1];
          this.filtered_data.price = [this.filtered_data.min_price, this.filtered_data.max_price];
          this.filtered_data.min_discount = this.discount_range[0];
          this.filtered_data.max_discount = this.discount_range[1];
          this.filtered_data.discount = [this.filtered_data.min_discount, this.filtered_data.max_discount];
          this.filtered_data.category = this.category_selection;
          localStorage.setItem("filtered_data",JSON.stringify(this.filtered_data));
        }
        this.searchShopItem();
      } else 
      {
        this.categroy_section = true;
        this.offer_section = false;
        this.issearch = false;
        this.searchproduct = "";
        if(localStorage.getItem("filtered_data")!=null){
          this.filtered_data = JSON.parse(localStorage.getItem("filtered_data"));
          this.filtered_data.food_type = this.food_type;
          this.filtered_data.brand = this.brand_selection;
          this.filtered_data.availability = this.out_of_stock;
          this.filtered_data.prescription = this.prescription;
          this.filtered_data.min_price = this.price_range[0];
          this.filtered_data.max_price = this.price_range[1];
          this.filtered_data.price = [this.filtered_data.min_price, this.filtered_data.max_price];
          this.filtered_data.min_discount = this.discount_range[0];
          this.filtered_data.max_discount = this.discount_range[1];
          this.filtered_data.discount = [this.filtered_data.min_discount, this.filtered_data.max_discount];
          localStorage.setItem("filtered_data",JSON.stringify(this.filtered_data));
        }
        this.getShopItemDetails();

      } 
    },
    filterShopItem(e){
      if (e.target != undefined && e.target.checked == false) {
        this.price_range = [0,0];
        this.discount_range = [0,0];
      }

      if(this.discount_range[0] >= this.discount_range[1] && this.discount_range[1] != 0){
        this.$refs.discount_range_slider.setValue([(this.discount_range[0] - 1), this.discount_range[1]]);
      }
      
      var sticky_banner = window.$('.shop-prifile-section').height();
      window.$("html, body").animate({ scrollTop: (sticky_banner - 220) }, "slow");
      this.hideCatagoryToggle();
      this.hideFilterToggle();
      if(this.searchproduct != '') {
        this.searchedproduct = this.searchproduct;
        this.categroy_section = true;
        this.subcategory = false;
        this.offer_section = false;
        this.page_number = 1;
        this.issearch = true;
        this.show_clear_search = true;
        if(localStorage.getItem("user_filter_data") != null) {
          this.user_filter_data = JSON.parse(localStorage.getItem("user_filter_data"));
          this.user_filter_data.search_query=this.searchproduct;
          this.user_filter_data.category_id =0;
          this.user_filter_data.category_name ="";
          localStorage.setItem("user_filter_data",JSON.stringify(this.user_filter_data));
        }
        this.searchShopItem();
      } else {
        this.categroy_section = true;
        this.offer_section = false;
        this.issearch = false;
        this.searchproduct = "";
        if(localStorage.getItem("filtered_data")!=null){
          this.filtered_data = JSON.parse(localStorage.getItem("filtered_data"));
          this.filtered_data.food_type = this.food_type;
          this.filtered_data.availability = this.out_of_stock;
          this.filtered_data.prescription = this.prescription;
          this.filtered_data.min_price = this.price_range[0];
          this.filtered_data.max_price = this.price_range[1];
          this.filtered_data.price = [this.filtered_data.min_price, this.filtered_data.max_price];
          this.filtered_data.min_discount = this.discount_range[0];
          this.filtered_data.max_discount = this.discount_range[1];
          this.filtered_data.discount = [this.filtered_data.min_discount, this.filtered_data.max_discount];
          localStorage.setItem("filtered_data",JSON.stringify(this.filtered_data));
        }
        this.getShopItemDetails();
      } 
    },
    filterComboAddons(addons){
      var tmp_addons = [];
      addons.forEach(addon => {
        addon.addon_prices.forEach(addon_price => {
          if(addon_price.is_added == 1){
            var obj = {};
            obj['variant_addon_name'] = addon.variant_addon_name;
            obj['variant_sub_addon_name'] = addon_price.variant_sub_addon_name;
            tmp_addons.push(obj);
          }
        });
      });
     return tmp_addons;
    },
    filterComboChoices(choices){
      var tmp_choices = [];
      choices.forEach(choice => {
        choice.choice_prices.forEach(choice_price => {
          if(choice_price.is_added == 1){
            var obj = {};
            obj['variant_choice_name'] = choice.variant_choice_name;
            obj['variant_sub_choice_name'] = choice_price.variant_sub_choice_name;
            tmp_choices.push(obj);
          }
        });
      });
     return tmp_choices;
    },
    clearBrandFilter(){
      this.brandsearch = "";
      this.filteredbrand_items = this.brand_items;
    },
    clearCategoryFilter(){
      this.categorysearch = "";
      this.filteredcategory_items = this.category_items_filter;
    },
    brandFilter(){
      if(this.brandsearch != "" && this.brandsearch) {
        this.filteredbrand_items = this.brand_items.filter(x => x.toLowerCase().match(this.brandsearch!=''?this.brandsearch.toLowerCase():''));
      } else {
        this.filteredbrand_items = this.brand_items;
      }
      
      return false;
    },
    categoryFilter(){
      if(this.categorysearch != "") {
        this.filteredcategory_items = this.category_items_filter.filter(x=>x.category_name.toLowerCase().indexOf(this.categorysearch.toLowerCase()) > -1);
      } else {
        this.filteredcategory_items = this.category_items_filter;
      }
      
      return false;
    },
    brand_toggle(brand_default_limit, filters_length) {
      this.brand_limit_by = (this.brand_limit_by === brand_default_limit) ? filters_length : brand_default_limit;
    },
    category_toggle(category_default_limit, filters_length) {
      this.category_limit_by = (this.category_limit_by === category_default_limit) ? filters_length : category_default_limit;
    },
    clearAllFilter(){
      this.food_type = [];
      this.prescription = [];
      this.out_of_stock = false;
      this.price_range = [0,0];
      this.discount_range = [0,0];
      this.brand_selection = [];
      this.category_selection = [];
      this.brandsearch = "";
      this.categorysearch = "";

      this.filtered_data.food_type = [];
      this.filtered_data.brand = [];
      this.filtered_data.availability = false;
      this.filtered_data.price = [0,0];
      this.filtered_data.discount = [0,0];
      localStorage.setItem("filtered_data", JSON.stringify(this.filtered_data));

      var sticky_banner = window.$('.shop-prifile-section').height();
      window.$("html, body").animate({ scrollTop: (sticky_banner - 220) }, "slow");
      this.hideFilterToggle();
      if(this.searchproduct != ''){
        this.searchShopItem();
      } else {
        this.getShopItemDetails();
      }
      this.closeFilter();
    },
  }
};
</script>
<style scoped>
  #shop-detail-section{
    padding-bottom: 0px !important;
  }
</style>